export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収拠点になる"])},
      "btn_tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収拠点として登録されると本プログラムの1回収拠点として地図に掲載されます。"])}
    }
  })
}
