export default {
  union: require('./images/union.png'),
  mainImage: require('./images/main.png'),
  leaf: require('./images/leaf.png'),
  frame: require('./images/frame.png'),
  logos: require('./images/logos.png'),
  tabIndicator: require('./images/tab-indicator.png'),
  tabIndicatorActive: require('./images/tab-indicator-active.png'),
  bgButton: require('./images/bg-button.png'),
  attention: require('./images/attention.png'),
  tab2Card1: require('./images/tab-2-card-1.png'),
  tab2Card2: require('./images/tab-2-card-2.png'),
  tab2Card3: require('./images/tab-2-card-3.png'),
  tab3Card1: require('./images/tab-3-card-1.png'),
  tab3Card2: require('./images/tab-3-card-2.png'),
  arrowDown: require('./images/arrow-down.png'),
  LDPE: require('./images/LDPE.png'),
  HDPE: require('./images/HDPE.png'),
  tcSymbol: require('./images/tc-symbol.png'),
};
