import { App } from 'vue';

export default {
  install(app: App) {
    app.component('CompaignHuggies', require('./compaign-huggies/index.vue').default);
    app.component('CompaignCocacola', require('./compaign-coca-cola/index.vue').default);
    app.component('CompaignEmart', require('./emart/index.vue').default);
    app.component('EmartProgram1', require('./emart/program-1.vue').default);
    app.component('EmartProgram2', require('./emart/program-2.vue').default);
    app.component('EmartProgram3', require('./emart/program-3.vue').default);
    app.component('EmartProgram4', require('./emart/program-4.vue').default);
    app.component('TemplateTerracycleIntro', require('./about/terracycle-intro.vue').default);
    app.component('TemplatesTrategicPartners', require('./about/strategic-partners').default);
    app.component('ProgramCollapse', require('./on-going-program/components/collapse.vue').default);
    app.component('OnGoingProgram', require('./on-going-program/index-view.vue').default);
    app.component('CampaignSSG', require('./campaign-ssg/index.vue').default);
    app.component('CampaignCocacolaSeason4', require('./campaign-cocacola-season-4/index.vue').default);
    app.component('CampaignCocacolaS5', require('./campaign-cocacola-season-5/index.vue').default);
  }
};
