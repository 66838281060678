export default {
  // Global
  'remark': '주목',
  'more': '더 보기',
  'No': '아니오',
  'Apply': '적용하기',
  'Start': '적용하기',
  'Save': '저장하기',
  'Return': '반품',
  'Close': '닫기',
  'Cancel': '취소',
  'Confirm': '확인',
  'Home': '집',
  'Request': '신청',
  'All status': '상태',
  'Tips': 'Tips',
  'Pending': '검토 중',
  'Approved': '승인됨',
  'Cancelled': '고객취소',
  'Rejected': '반려됨',
  'Closed': '만료됨',
  'wait_listed': '대기 중',
  'Success': '완료 되었습니다',
  'Submit': '제출하기',
  'Submit success!': '제출이 완료 되었습니다',
  'Operation success': '정보가 성공적으로 입력 되었습니다',
  'Please sign in': '로그인 해주세요',
  'Sign in': '로그인',
  'Sign In': '로그인',
  'Sign Up': '회원 가입하기',
  'Sign In Partner': '파트너 계정 가입하기',
  'The session has expired. Please log in again': '세션이 만료 되었습니다 다시 로그인해주세요',
  'Individual': '개인',
  'Group': '단체',
  'Please check the required': '필수 입력사항을 확인 해주세요',
  'No search results': '검색 결과 없음',
  'phoneNumberIsInValid': '휴대폰 번호는 숫자로만 입력 가능합니다',
  'EMAIL ADDRESS': '이메일 주소',
  'PHONE NUMBER': '전화번호',
  'PHONE NUMBER FORM LABEL': '휴대 전화',
  'PASSWORD': '비밀번호',
  'CONFIRM PASSWORD': '비밀번호 확인',
  'VERIFICATION CODE': '인증번호',
  'Login success!': '로그인이 완료 되었습니다',
  'termsOfUse': '이용약관',
  'operationPolicy': '운영정책',
  'privacyPolicy': '개인정보처리방침',
  'mediaRoom': '뉴스룸',
  'locationBased': '위치기반 서비스 이용약관',
  'contactUs': '문의하기',
  'mine_page': '마이페이지',
  'Submit': '제출하기',
  'Yes': '예',
  'No': '아니오',
  'Please login': '로그인 해주세요',
  'All': 'All',
  // menu
  'About': 'About TerraCycle',
  'TerraCycle Intro': '테라사이클',
  'Strategic Partners': '테라사이클 협력사',
  'Program': 'Recycle',
  'Views All Programs': '모든 캠페인 보기',
  'Contests and Promotions': '이벤트',
  'Shop': 'Shop',
  'Story': 'Story',
  'Zero Waste Box': '제로웨이스트박스',
  'TC Select Shop': 'TC Select Shop',
  'Contact Us': 'Contact Us',
  'Customer Service Center': 'CS 센터',
  'Business inquiry': '사업 제휴문의',
  'Inquiry To Use': '이용문의',
  'FAQ': '자주하는 질문',
  'Inconsistent password input': '비밀번호가 일치하지 않습니다',
  // mine page menu
  'Member profile': '회원 정보',
  'TC Point': '테라사이클 포인트',
  'Wish list': '위시리스트',
  'My orders': '내 주문 내역',
  'Membership grade': '회원 등급',
  'My Program': '마이 캠페인',
  'Program participation record': '캠페인 신청 내역',
  'Membership ID with QR code': 'QR코드',
  'Detail': '세부사항',
  'My QR code': '내 QR 코드',
  'Incentive Campaign List': '인센티브 캠페인 목록',
  'PDO Dashboard': 'PDO 대시보드',
  'My Coupon': '내 쿠폰',
  // shipment
  'Completed request': '요청 완료',
  'Delivery': '수거 중',
  'Arrival': '입고',
  'Inspection': '검수',
  'Recycle': '재활용',
  // gender
  'Male': '남성',
  'Female': '여성',
  'Prefer not to answer': '비공개',
  // chanel
  'Article': '기사',
  'Website': '웹사이트',
  'Friend': '친구',
  'Twitter': '트위터',
  'Facebook': '페이스북',
  'Pinterest': '핀터레스트',
  'Instagram': '인스타그램',
  'Search': '검색',
  'Strategic Partnerships': '전략적 파트너십',
  'Collection History': '수거 신청 내역',
  // on going program
  'on_going_program_apply_success_content': '수거 신청 접수가 완료 되었습니다. 회원 정보에 기입된 주소로 영업일 기준 3~4일내로 한진택배 기사님이 방문할 예정입니다',
  'on_going_program_apply_success_button': '확인',
  'on_going_program_join_waiting_list_success_content': '현재 신청자가 많아 대기자 명단에 추가 되었습니다. 대기 순번 차례가 돌아올 경우 이메일로 안내 드리겠습니다.',
  'on_going_program_join_waiting_list_success_button': '확인',
  'on_going_program_error_button': '닫기',
  // offline collection
  offline_collection: {
    back_to_home_page: '홈으로 돌아가기',
    done: '완료',
    step_index: '{index} 단계',
    oc_collection_history: '오프라인 수거 내역',
    request_reward: '요청하기',
    oc_records: '오프라인 수집 기록',
    oc_reward_type: {
      points: '포인트',
      coupon: '쿠폰'
    },
    oc_reward_state: {
      init: '대기 중인 신청 내역',
      pending: '승인 대기 중',
      approved: '승인됨',
      rejected: '반려됨',
    },
    form: {
      'Collected Bin QR code': '수거함 이름',
      'Scan membership code': '멤버십 코드 스캔',
      'Scan QR barcode on the collected item': '수거 품목 QR 스캔하기',
      'Add more item': '수거 품목 추가하기',
      'Please scan the membership code': '멤버십 코드를 스캔해주세요',
      'Membership code is invalid': '잘못된 회원 코드입니다',
      'Please add barcode': '수거 품목을 추가해주세요',
      'Submitted Successfully!': '성공적으로 접수 되었습니다. 마이페이지에서 오프라인 수거 내역을 확인하세요.',
      'We will email you once the rewards have been approved!': '리워드 지급 승인 시 안내 메일이 발송 예정입니다',
      'Start Over': '다시 하기',
      'Check my OC records': '오프라인 수거 내역으로 돌아가기'
    },
    record: {
      'Name': '이름',
      'Created at': '생성일시',
      'Bin Name': '수거함명',
      'Barcode': '바코드',
      'New Submission': '신규 접수',
    }
  },
  redemption: {
    state: {
      requested: '접수 완료 되었습니다',
      sent: '전송됨',
      cancelled: '취소됨'
    }
  },
  participate: {
    join_content: '반납 항목을 등록하려면 프로그램에 가입해야 합니다. 프로그램에 참여하시겠습니까?',
    join_success_content: '프로그램에 참여해 주셔서 감사합니다. 픽업 준비가 완료되면 프로그램 페이지에서 픽업 요청 절차를 부탁드립니다.'
  }
};
