export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Contact information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
      "Additional information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information"])},
      "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CURRENT_PASSWORD"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
    }
  })
}
