export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "AGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGE"])},
      "GENDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GENDER"])},
      "HOW DID YOU HEAR ABOUT US?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOW DID YOU HEAR ABOUT US?"])},
      "ORGANIZATION NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORGANIZATION NAME"])},
      "ORGANIZATION TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORGANIZATION TYPE"])},
      "PEOPLE COLLECTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PEOPLE COLLECTING"])},
      "organization_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization name"])},
      "people_collection_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter an integer number greater than 0"])}
    }
  })
}
