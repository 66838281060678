export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Redeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem"])},
      "Redeem points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem points"])},
      "Not enough points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough points"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The operation is successful, please wait for the administrator to review"])},
      "Current Points Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Points Balance"])}
    }
  })
}
