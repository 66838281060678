export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Sign in via Terracycle website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in via Terracycle website"])},
      "or Sign in via SNS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or Sign in via SNS"])},
      "Sign in via SNS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in via SNS"])},
      "Don't have an account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])}
    }
  })
}
