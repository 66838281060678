export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Searh campaign participation record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searh campaign participation record"])},
      "No Program participation record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Program participation record"])}
    }
  })
}
