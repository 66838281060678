<template>
  <div class="section-3">
    <div class="container">
      <img class="img-ripple" src="../../images/about-ripple.png">
      <img class="img-ripple-2" src="../../images/about-ripple.png">

      <div class="section-title">
        우리가 함께한 오늘의 실천이 쌓여
      </div>
      <div class="section-title">
        아래와 같이 재활용됩니다.
      </div>
      <div class="box-steps">
        <div class="step-card step-1">
          <div class="step-header">
            <div class="step-info">
              <div class="step-index">
                STEP 1.
              </div>
              <div class="step-name">
                방문 수거
              </div>
            </div>
            <img :src="require('./images/step-1.png')">
          </div>
          <div class="step-content">
            <div>온/오프라인을 통해 여러분들이</div>
            <div>모아 주신 플라스틱을 모두 수거</div>
            <div>해 갑니다</div>
          </div>
        </div>
        <div class="step-card step-2">
          <div class="step-header">
            <div class="step-info">
              <div class="step-index">
                STEP 2.
              </div>
              <div class="step-name">
                분류/세척
              </div>
            </div>
            <img :src="require('./images/step-2.png')">
          </div>
          <div class="step-content d-md-none">
            <div>폐플라스틱이 소중한 자원이 될 수</div>
            <div>있도록 재질별 분류 및 세척 과정을</div>
            <div>거칩니다</div>
          </div>
          <div class="step-content d-none d-md-block">
            <div>폐플라스틱이 소중한 자원이</div>
            <div>될 수 있도록 재질별 분류 및</div>
            <div>세척 과정을 거칩니다</div>
          </div>
        </div>
        <div class="step-card step-3">
          <div class="step-header">
            <div class="step-info">
              <div class="step-index">
                STEP 3.
              </div>
              <div class="step-name">
                재활용 공정
              </div>
            </div>
            <img :src="require('./images/step-3.png')">
          </div>
          <div class="step-content">
            <div>테라사이클을 통해</div>
            <div>재활용 공정을 거쳐</div>
            <div>새로운 자원이 될 준비를 합니다</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style scoped lang="scss">
  @import '../../vars.scss';

  .section-3 {
    padding-top: vwMobile(50px);
    background: #f7f7f7;

    @include media-breakpoint-up(md) {
      padding-top: vw(145px);
    }
  }

  .container {
    position: relative;
  }

  .img-ripple, .img-ripple-2 {
    position: absolute;
    width: vw(309px);

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .img-ripple {
    top: vw(60px);
    left: vw(-200px);
  }

  .img-ripple-2 {
    bottom: vw(130px);
    right: 0;
    transform: translateX(50%);
  }

  .section-title {
    font-size: vwMobile(24px);
    font-family: SCDream7;
    line-height: 1.47;
    text-align: center;
    color: #3b393a;

    @include media-breakpoint-up(md) {
      font-size: vw(58px);
      font-family: SCDream8;
    }
  }

  .box-steps {
    position: relative;
    z-index: 2;
    display: flex;
    padding-top: vwMobile(57px);
    padding-bottom: vwMobile(112px);
    overflow: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(md) {
      justify-content: center;
      padding-top: vw(105px);
      padding-bottom: vw(270px);
    }
  }

  .step-card {
    flex-shrink: 0;
    width: vwMobile(271px);
    height: vwMobile(358px);
    padding-right: vwMobile(28px);
    padding-left: vwMobile(28px);
    margin-right: vwMobile(15px);
    border-radius: vwMobile(36px);
    background: #fff;
    box-shadow: 3px 4px 22px rgba(145, 145, 145, 0.25);

    @include media-breakpoint-up(md) {
      width: vw(370px);
      height: unset;
      padding-right: vw(39px);
      padding-bottom: vw(65px);
      padding-left: vw(28px);
      margin-right: 0;
      border-radius: vw(36px);

      &:not(:last-child) {
        margin-right: vw(30px);
      }
    }

    @include media-breakpoint-down(md) {
      &:first-child {
        margin-left: vwMobile(56px);
      }

      &:last-child {
        margin-right: vwMobile(56px);
      }
    }


    .step-header {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: vwMobile(26px);
      padding-bottom: vwMobile(8px);

      @include media-breakpoint-up(md) {
        padding-top: vw(36px);
        padding-bottom: vw(16px);
      }

      .step-info {
        font-family: Noto Sans KR;
        letter-spacing: -0.02em;

        .step-index {
          font-weight: bold;
          font-size: vwMobile(20px);
          font-family: SCDream7;
          color: #ffc521;

          @include media-breakpoint-up(md) {
            font-size: vw(24px);
            line-height: 1.1;
          }
        }

        .step-name {
          margin-top: vwMobile(5px);
          font-size: vwMobile(18px);

          @include media-breakpoint-up(md) {
            margin-top: vw(7px);
            font-size: vw(36px);
          }
        }
      }

      img {
        width: vwMobile(135px);
        height: auto;
        margin-bottom: vwMobile(17px);

        @include media-breakpoint-up(md) {
          width: vw(184px);
          margin-bottom: vw(44px);
        }
      }
    }

    .step-content {
      padding-top: vwMobile(14px);
      border-top: 1px solid #3b393a;
      font-size: vwMobile(14px);
      font-family: AppleSDGothicNeoB;
      line-height: 1.71;
      white-space: initial;
      letter-spacing: -0.03em;

      @include media-breakpoint-up(md) {
        padding-top: vw(19px);
        font-size: vw(24px);
        line-height: 1.66;
      }
    }
  }
</style>
