export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
      "button_age_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["を登録する"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いいえ"])}
    }
  })
}
