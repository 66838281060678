export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Contact information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연락처 정보"])},
      "Additional information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인적 사항"])},
      "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 비밀번호"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])}
    }
  })
}
