import { BaseType, getBaseData } from './base';
import { Prize, getPdoDefaultPrize } from './prize';
import { PointsType, getPointDefaultData } from './points';

export interface Redemption extends BaseType {
  prize_id: number;
  state: string;
  prize: Prize,
  point_record: PointsType
}

export function getPdoDefaultRedemption(): Redemption {
  return {
    ...getBaseData(),
    prize_id: 0,
    state: '',
    prize: getPdoDefaultPrize(),
    point_record: getPointDefaultData()
  };
}
