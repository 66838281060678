export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "individual_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
      "individual_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can do it on my own!"])},
      "group_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group(3~4 people or more)"])},
      "group_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to do it together!"])}
    }
  })
}
