export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Verification code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호 받기"])},
      "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호 전송"])},
      "CAPTCHA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보안인증"])},
      "Captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보안인증"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["핸드폰"])},
      "Phone is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호는 필수 입력 항목입니다"])},
      "Email address is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 주소는 필수 입력 항목입니다"])},
      "Send verification code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호 받기"])},
      "Captcha is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보안문자를 입력해주세요"])},
      "sended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전송 완료"])}
    }
  })
}
