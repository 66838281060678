export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Sign Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])},
      "Sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
      "Search programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재활용 캠페인 검색"])}
    }
  })
}
