export default {
  bannerHanjin: require('./images/banner-hanjin.jpg'),
  bannerHanjinIntro: require('./images/banner-hanjin-intro.png'),
  bannerTc: require('./images/banner-tc.png'),
  bannerTcIntro: require('./images/banner-tc-intro.png'),
  logo1: require('./images/logo1.jpg'),
  logo2: require('./images/logo2.jpg'),
  logo3: require('./images/logo3.jpg'),
  logo4: require('./images/logo4.jpg'),
  logo5: require('./images/logo5.jpg'),
  logo6: require('./images/logo6.jpg'),
  logo7: require('./images/logo7.jpg'),
  logo8: require('./images/logo8.jpg'),
  logo9: require('./images/logo9.jpg'),
  logo10: require('./images/logo10.jpg'),
  logo11: require('./images/logo11.jpg'),
  logo12: require('./images/logo12.jpg'),
  logo13: require('./images/logo13.jpg'),
  logo14: require('./images/logo14.jpg'),
  logo15: require('./images/logo15.jpg'),
  logo16: require('./images/logo16.jpg'),
  logo17: require('./images/logo17.jpg'),
  logo18: require('./images/logo18.jpg'),
  logo19: require('./images/logo19.jpg'),
  logo20: require('./images/logo20.jpg'),
  logo21: require('./images/logo21.jpg'),
  logo22: require('./images/logo22.jpg'),
  logo23: require('./images/logo23.jpg'),
  logo24: require('./images/logo24.jpg'),
  logo25: require('./images/logo25.jpg'),
  logo26: require('./images/logo26.jpg'),
};
