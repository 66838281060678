export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Reset password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
      "PHONE NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHONE NUMBER"])},
      "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EMAIL"])},
      "CAPTCHA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAPTCHA"])}
    }
  })
}
