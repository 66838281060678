export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご協力ありがとうございます！"])},
      "success_reward_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご協力ありがとうございます！ポイント、もしくはクーポンが付与されましたらメールにてご連絡します。"])},
      "can_submit_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["あと", _interpolate(_named("num")), "個登録が可能です。"])},
      "can_submit_for_campaign": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keep it up!\nこのキャンペーンにはまだ ", _interpolate(_named("num")), " 個のコンテナを提出できます!"])},
      "well_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\n提出可能な回収物の最大数を達成しました。ご参加いただきありがとうございます!\n\n"])},
      "well_done_not_limited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\nご参加いただきありがとうございます！\n\n"])},
      "oc_tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得されたクーポンもしくはポイントは、マイページにて確認できます"])}
    }
  })
}
