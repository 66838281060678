export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "TerraCycle Recycling Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テラサイクル リワードアイテム"])},
      "Your current have:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在お持ちのポイント数:"])},
      "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイント"])},
      "Redeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用する"])},
      "Redeem points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイントを使用する"])},
      "Add new charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい寄付先 (チャリティ) を登録する"])},
      "Please fill in the points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必要な項目を全てご入力ください"])},
      "Not enough points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイントが不足しています"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請を受け付けました。申し込みが承認されるまでお待ちください。"])},
      "Search for an existing charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録済みの寄付先を検索"])},
      "Current Points Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のポイント残高"])}
    }
  })
}
