import { TPurchaseKind } from './product';

interface IOrderLineItem {
  product_name: string;
  quantity: number;
  purchase_kind: TPurchaseKind;
  price: string;
  variant_id: number;
  option_types: Array<{ name: string; value: string }>;
}

export interface IOrder {
  id: number;
  line_items: IOrderLineItem[];
  total: string;
}

export default function(): IOrder {
  return {
    id: 0,
    total: '0',
    line_items: []
  };
};
