import script from "./additional-information-form.vue?vue&type=script&lang=ts&setup=true"
export * from "./additional-information-form.vue?vue&type=script&lang=ts&setup=true"
/* custom blocks */
import block0 from "./additional-information-form.vue?vue&type=custom&index=0&blockType=i18n&locale=en"
if (typeof block0 === 'function') block0(script)
import block1 from "./additional-information-form.vue?vue&type=custom&index=1&blockType=i18n&locale=ko"
if (typeof block1 === 'function') block1(script)
import block2 from "./additional-information-form.vue?vue&type=custom&index=2&blockType=i18n&locale=ja"
if (typeof block2 === 'function') block2(script)


const __exports__ = script;

export default __exports__