export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store person scan your membership code."])},
      "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store person scan the barcode of your collected items."])},
      "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once your uploaded the information, you can drop the container into the bin."])}
    }
  })
}
