export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "participate_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プログラムに参加"])},
      "dialog_login_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインインしてください"])},
      "dialog_login_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン"])},
      "dialog_email_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プログラムの参加には、メールアドレスの認証が必要になります（初回のみ）。"])},
      "dialog_email_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを認証する"])},
      "dialog_address_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プログラムへの参加には、住所と電話番号の登録が必要です"])},
      "dialog_address_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録する"])},
      "dialog_info_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
      "dialog_participate_success_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プログラムへの参加を申請いただきありがとうございます。"])},
      "apply_for_collection_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンラインで集荷を申し込む"])}
    }
  })
}
