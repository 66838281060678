import { FormItem, FormItemType, FormItemOption  } from '@/types/form';
import { ISurvey  } from '@/types/survey';
import { AnswersAttributes  } from '@/types/responses';
import _ from 'lodash';
// import { useToast } from 'bootstrap-vue-3';
import i18n from '@/i18n';

const t = i18n.global.t;

export function getFormItemType(kind: string): FormItemType {
  switch (kind) {
    case 'choice':
      return 'select';
    case 'multiple_choice':
      return 'checkbox';
    case 'text':
      return 'textarea';
    default:
      return 'input';
  }
}

export function resolveOptions(options: any): FormItemOption[] {
  if (!options || !Array.isArray(options)) {
    return [];
  }
  return options.map((item: any) => {
    return {
      value: item.id,
      text: item.text
    };
  });
}

export function getQuestions(data: ISurvey): FormItem[] {
  const questions = _.flatMap(data.survey_sections, item => item.survey_questions);
  const formList: FormItem[] = [];
  questions.forEach((item: any) => {
    formList.push({
      label: item.text,
      type: getFormItemType(item.kind),
      required: item.is_mandatory,
      placeholder: item.tips,
      keyForSpecifySurvey: item.key_for_specify_survey,
      key: item.id,
      inputType: 'text',
      options: resolveOptions(item.survey_options)
    });
  });
  return formList;
}

export function getAnswers(data: {[key: string]: any}, questions: FormItem[]): AnswersAttributes[] {
  const answersAttributes: AnswersAttributes[] = [];
  questions.forEach((item: any, index) => {
    const value = data[item.key];
    if (item.required && (!value || (Array.isArray(value) && !value.length))) {
      const err = t('Please check the required');
      // TODO： useToast 只能再setup 中使用，后面再看看这块如何处理
      // const toast = useToast();
      // toast.show({ title: 'Tips', body: err }, { variant: 'danger' });
      throw new Error(err);
    }
    const answer: any = {
      // 暂时写死 text, 等待后端修改接口
      value_type: 'text',
      survey_question_id: questions[index].key,
    };
    if (/input|textarea/.test(item.type)) {
      answer.value = value;
    } else {
      answer.survey_option_ids = [].concat(value);
    }
    answersAttributes.push(answer);
  });
  return answersAttributes;
}
