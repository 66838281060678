export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "available_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용 시간"])},
      "go_shopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쇼핑하러 가기"])},
      "coupon_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 세부정보"])},
      "make_this_coupon_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 쿠폰을 사용하세요"])},
      "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 전 버튼을 클릭하시면 쿠폰이 무효가 됩니다."])},
      "make_this_coupon_used_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 쿠폰을 사용하세요?"])}
    }
  })
}
