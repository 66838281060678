export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객님의 요청이 성공적으로 접수 되었습니다!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인시 별도 연락드릴 예정입니다. 잠시만 기다려 주세요!"])},
      "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])}
    }
  })
}
