import { BaseType, getBaseData } from './base';
import { IImage } from './image';

export interface IOfflineCollection extends BaseType {
  name: string;
  avaliable_barcodes: string;
  collection_method: 'user_registration' | 'store_staff_registration';
  limitation: number;
  limitation_method: 'per_day' | 'per_campaign';
  oc_coupon_template_id: number;
  points: number;
  reward_coupon: boolean;
  reward_point: boolean;
  ended_at: string;
  started_at: string;
  description: string;
  image?: IImage;
  state: 'active' | 'inactive'
}

export function getOfflineCollectionDefaultData(): IOfflineCollection {
  return {
    name: '',
    avaliable_barcodes: '',
    collection_method: 'user_registration',
    limitation: 0,
    limitation_method: 'per_day',
    oc_coupon_template_id: 0,
    points: 0,
    reward_coupon: false,
    reward_point: false,
    ended_at: '',
    started_at: '',
    description: '',
    state: 'inactive',
    ...getBaseData(),
  };
}
