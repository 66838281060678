import { BaseType, getBaseData } from './base';
import { ICharity, getCharityDefaultData } from './charity';
import { IImage } from './image';

export interface PrizeCategory extends BaseType {
  name: string;
  short_description: string;
  prizes_count: number;
}

export function getPdoDefaultPrizeCategory(): PrizeCategory {
  return {
    ...getBaseData(),
    name: '',
    short_description: '',
    prizes_count: 0
  };
}

export interface Prize extends BaseType {
  name: string;
  partner_url: string;
  short_description: string;
  charity_id: number;
  point_cost: number;
  prize_category_id: number;
  status: string;
  long_description: string;
  images: IImage[];
  charity: ICharity;
  prize_category: PrizeCategory
}

export function getPdoDefaultPrize(): Prize {
  return {
    ...getBaseData(),
    name: '',
    partner_url: '',
    short_description: '',
    charity_id: 0,
    point_cost: 0,
    prize_category_id: 0,
    status: '',
    long_description: '',
    images: [],
    charity: getCharityDefaultData(),
    prize_category: getPdoDefaultPrizeCategory()
  };
}
