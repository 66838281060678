export interface IAddress {
  id: number;
  first_name: string;
  last_name: string;
  phone_number: string;
  postal_code: string;
  country_id: number;
  country?: {
    id: number;
    name: string;
    code: string;
  };
  province_id: number;
  province?: {
    id: number;
    name: string;
    code: string;
  };
  city: string;
  address1: string;
  address2?: string;
  company?: string;
  is_default?: boolean;
}

export default function(): IAddress {
  return {
    id: 0,
    first_name: '',
    last_name: '',
    phone_number: '',
    postal_code: '',
    country_id: 0,
    province_id: 3428,
    city: '',
    address1: '',
    address2: '',
    company: '',
    is_default: false
  };
}
