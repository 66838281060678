// https://github.com/yabwe/medium-editor/issues/523#issuecomment-312204485

import MediumEditor from 'medium-editor';
import './vanilla-color-picker.js';

const ColorPickerExtension = (MediumEditor as any).Extension.extend({
  name: 'colorPicker',

  init: function () {
    this.button = this.document.createElement('button');
    this.button.classList.add('medium-editor-action');
    this.button.classList.add('editor-color-picker');
    this.button.title = 'Text color';
    this.button.innerHTML = '<span>COLOR</span>';

    this.on(this.button, 'click', this.handleClick.bind(this));
  },

  getButton: function () {
    return this.button;
  },

  handleClick: function (e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.selectionState = this.base.exportSelection();

    // If no text selected, stop here.
    if (this.selectionState && (this.selectionState.end - this.selectionState.start === 0)) {
      return;
    }

    // colors for picker
    let pickerColors = [
      '#006225',
      '#000000',
      '#525355'
    ];

    const picker = window.vanillaColorPicker(this.document.querySelector('.medium-editor-toolbar-active .editor-color-picker').parentNode);
    picker.set('customColors', pickerColors);
    picker.set('positionOnTop');
    picker.openPicker();
    picker.on('colorChosen', function (this: any, color: string) {
      this.base.importSelection(this.selectionState);
      this.document.execCommand('styleWithCSS', false, true);
      this.document.execCommand('foreColor', false, color);
    }.bind(this));
  }
});

export default ColorPickerExtension;
