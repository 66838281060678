export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Show less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["간략히 보기"])},
      "Show more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 보기"])}
    }
  })
}
