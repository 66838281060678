export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Email information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 정보"])},
      "Password information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 정보"])},
      "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 비밀번호"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])},
      "Verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인됨"])},
      "Inconsistent password input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일치하지 않는 비밀번호입니다"])},
      "Email not verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 인증이 완료 되지 않았습니다. "])}
    }
  })
}
