import { request } from '@/utils';
import { SimpleStore } from './helper/simple-store';
import { ISurvey  } from '@/types/survey';
import _ from 'lodash';

type SurveyPopup = 'after_user_register' | 'after_member_register' | 'after_login' | 'after_join_program' | 'after_delivering_item';

class UserSurveysStore extends SimpleStore {
  data: ISurvey = {}
  params: {
    type_eq: 'UserSurvey';
    popup_scene_eq?: SurveyPopup;
    program_id: undefined | number;
  } = {
    type_eq: 'UserSurvey',
    popup_scene_eq: undefined,
    program_id: undefined,
  }

  showModal = false

  history = {}

  getHistoryKey(params: Record<string, any>) {
    return [params.popup_scene_eq, params.program_id].filter(Boolean).join('_');
  }

  async fetch(agrs?: any) {
    const params = {
      ...this.params,
      ...agrs
    };
    const res = await request.get('surveys/current', { params });
    const historyKey = this.getHistoryKey(params);
    return this.history[historyKey] = this.data = res.data || {};
  }

  async tryPopup(scene: SurveyPopup, program_id?: number) {
    this.params.popup_scene_eq = scene;
    this.params.program_id = program_id;
    const historyKey = this.getHistoryKey(this.params);
    if (!this.history.hasOwnProperty(historyKey)) {
      await this.fetchData();
    }
    if (_.isEmpty(this.history[historyKey])) {
      this.data = this.history[historyKey] || {};
    }
    if (!_.isEmpty(this.data)) {
      this.showModal = true;
    }
  }

  clearHistory() {
    // 可能登录之前有问卷，登录之后没有了，所以登录之后需要清空一下缓存
    this.history = {};
  }

  async submit(body: any) {
    await request.post('responses', body);
    const historyKey = this.getHistoryKey(this.params);
    this.history[historyKey] = null;
    this.showModal = false;
  }
}

export const userSurveysStore = UserSurveysStore.create<UserSurveysStore>();
