export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적용하기"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여신청"])}
    }
  })
}
