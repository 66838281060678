export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["고객님은 ", _interpolate(_named("program")), "의 수거 거점 등록을 신청 중입니다"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회수 거점으로 등록되면 본 프로그램의 1회 수거점으로 지도에 게재됩니다."])},
      "facility_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청 단체명"])},
      "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우편번호"])},
      "address_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
      "address_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상세 주소"])},
      "opening_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영업시간"])},
      "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정확한 정보를 입력 했는지 확인 해주세요. 승인 시 수거 거점의 위치 정보가 지도에 추가 됩니다."])},
      "show_phone_on_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지도에 휴대폰 번호 표시 여부"])},
      "phone_number_tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호 게재를 희망하시는 경우에만 입력해 주십시오."])}
    }
  })
}
