export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로 고치다"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
      "toogleHasContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["컨테이너 토글"])},
      "moveUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위로"])},
      "moveDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아래에"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집하다"])},
      "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["돌아가기"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시"])}
    }
  })
}
