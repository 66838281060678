import { loadScript } from 'vue-plugin-load-script';
import router from '@/router';
import urlJoin from 'url-join';

async function initKakaoSdk() {
  await loadScript(process.env.BASE_URL + 'kakao.js');
  if (!Kakao.isInitialized()) {
    Kakao.init(process.env.VUE_APP_KAKAO_SDK_KEY);
  }
}

interface IKakaoShare {
  title: string;
  description?: string;
  imageUrl?: string;
  link?: string;
}

export async function kakaoShare(shareContent: IKakaoShare) {
  await initKakaoSdk();
  const shareUrl = shareContent.link || urlJoin(location.origin, router.currentRoute.value.fullPath);
  const shareContentObj = {
    title: shareContent.title,
    description: shareContent.description || '',
    imageUrl: shareContent.imageUrl || '',
    link: {
      webUrl: shareUrl,
      mobileWebUrl: shareUrl
    }
  };
  Kakao.Link.sendDefault({
    objectType: 'feed',
    content: shareContentObj
  });
  // eslint-disable-next-line no-console
  console.info(shareContentObj, 'kakaoShare');
}

export function lineShare(url: string = location.href) {
  window.open('https://lineit.line.me/share/ui?url=' + encodeURIComponent(url), '_blank', 'toolbar=yes,location=yes,directories=no,status=no, menubar=yes,scrollbars=yes,resizable=no, copyhistory=yes,width=600,height=400');
}

export function facebookShare(url: string = location.href) {
  window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url), '_blank');
}

export function twitterShare(url: string = location.href, text: string = '') {
  window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${text}`, '_blank');
}
