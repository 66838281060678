export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well done! Thanks for joining our offline collection."])},
      "success_reward_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well done! Thank you for your participation. we will inform you via email once the coupon or tc point is issued."])},
      "can_submit_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keep it up!\nYou still can submit ", _interpolate(_named("num")), " containers today!"])},
      "can_submit_for_campaign": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keep it up!\nYou still can submit ", _interpolate(_named("num")), " containers for this campaign!"])},
      "well_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well done!\nYour submission quota has been exhausted, thank you for your participation!\n\nSee you next time!"])},
      "well_done_not_limited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well done!\nThank you for your participation!\n\nSee you next time!"])},
      "oc_tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The obtained coupons or points can be confirmed on the \"My Page\"."])}
    }
  })
}
