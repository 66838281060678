<template>
  <section class="page-3">
    <div class="container">
      <div class="section-3-1">
        <div class="section-title">
          <span>원더플 캠페인</span>
          <span>&nbsp;운영 기간</span>
        </div>
        <div class="box-cycle">
          <div class="cycle-card">
            <h6 class="card-title">
              Cycle 1.
            </h6>
            <div class="card-content">
              <div class="item">
                <span>모집 일정</span>
                <span>5월 12일 ~ 25일 (2주)</span>
              </div>
              <div class="item">
                <span>당첨자 선정</span>
                <span>5월 27일</span>
              </div>
              <div class="item">
                <span>수거 기간</span>
                <span>6월 3일 ~ 8월 3일 (60일)</span>
              </div>
              <div class="item">
                <span>리워드 발송</span>
                <span>7월 초부터 순차 발송</span>
              </div>
            </div>
          </div>
          <div class="cycle-card">
            <h6 class="card-title">
              Cycle 2.
            </h6>
            <div class="card-content">
              <div class="item">
                <span>모집 일정</span>
                <span>6월 27일 ~ 7월 10일 (2주)</span>
              </div>
              <div class="item">
                <span>당첨자 선정</span>
                <span>7월 12일</span>
              </div>
              <div class="item">
                <span>수거 기간</span>
                <span>7월 18일 ~ 9월 18일 (60일)</span>
              </div>
              <div class="item">
                <span>리워드 발송</span>
                <span>8월 초부터 순차 발송</span>
              </div>
            </div>
          </div>
          <div class="cycle-card">
            <h6 class="card-title">
              Cycle 3.
            </h6>
            <div class="card-content">
              <div class="item">
                <span>모집 일정</span>
                <span>8월 8일 ~ 21일 (2주)</span>
              </div>
              <div class="item">
                <span>당첨자 선정</span>
                <span>8월 23일</span>
              </div>
              <div class="item">
                <span>수거 기간</span>
                <span>8월 29일 ~ 10월 29일 (60일)</span>
              </div>
              <div class="item">
                <span>리워드 발송</span>
                <span>9월 초부터 순차 발송</span>
              </div>
            </div>
          </div>
        </div>
        <div class="notice">
          <div class="notice-title">
            NOTICE
          </div>
          <div class="notice-content">
            <p>
              * 리워드 발송은 입고 및 검수 기간을 고려하여 약 2주가 소요됩니다.
              <br class="d-block d-md-none">
              조금만 기다려 주세요.
            </p>
            <p>
              * 무작위 추첨제로 개인 당첨자로 선정될 경우 개별 연락드립니다.
            </p>
            <p>
              * 단체의 경우  신청해 주신 대표자의 연락처로 개별 연락드립니다.
            </p>
          </div>
        </div>
      </div>
      <div class="section-3-2">
        <div class="section-title">
          <span>원더플 캠페인</span>
          <span>&nbsp;참여 방법</span>
        </div>
        <div class="box-step">
          <div class="step-card-container step-card-container-1">
            <div class="step-card step-1">
              <div class="step-name">
                STEP 1.
              </div>
              <div class="card-name">
                캠페인&nbsp;<span class="strong">참여 신청</span>
              </div>
              <div class="box-index">
                <span class="index-name">1</span>
                신청 대상
              </div>
              <div class="card-content">
                <div class="p">
                  <span class="symbol">-</span>
                  <span class="content">원더플 지구를 함께 만들 개인/단체</span>
                </div>
                <div class="p">
                  <span class="symbol">-</span>
                  <span class="content">원더플 피플 누구나</span>
                </div>
                <div class="p small">
                  <span class="symbol">•</span>
                  <div class="content">
                    단체 참여 시 인원 제한은 없으나 리워드는 <br class="d-block d-md-none">
                    정해진 수량만 제공 <br class="d-none d-md-block">된다는 점 참고해주세요!
                  </div>
                </div>
                <div class="p small d-none d-md-block" style="visibility: hidden">
                  <span class="symbol">•</span>
                </div>
              </div>
              <div class="box-index">
                <span class="index-name">2</span>
                신청 방법
              </div>
              <div class="card-content">
                <div class="p">
                  <span class="symbol">-</span>
                  <span class="content">
                    SSG.COM, G마켓 또는 Coke PLAY 앱을<br>
                    통해 참여자 모집
                  </span>
                </div>
                <div class="p">
                  <span class="symbol">-</span>
                  <span class="content">단체의 경우, 참여하는 단체명을 기재</span>
                </div>
                <div class="p small">
                  <span class="symbol">•</span>
                  <span class="content">(예. 회사명, 동호회 명)</span>
                </div>
              </div>
            </div>
          </div>

          <div class="step-card-container step-card-container-2">
            <div class="step-card step-2">
              <div class="step-name">
                STEP 2.
              </div>
              <div class="card-name">
                당첨자&nbsp;<span class="strong">발표</span>
              </div>
              <div class="box-index">
                <span class="index-name">1</span>
                각 회차 (CYCLE) 별
              </div>
              <div class="card-content has-img">
                <img class="img-product" :src="require('./images/img-product.png')">
                <div class="p">
                  <span class="symbol">-</span>
                  <span class="content">개인 1,350명씩 및 개별 발표 </span>
                </div>
                <div class="p">
                  <span class="symbol">-</span>
                  <span class="content">단체 20팀 선정 및 개별 발표</span>
                </div>
                <div class="p small">
                  <span class="symbol">•</span>
                  <div class="content">
                    원더플 피플의 의견을 반영해 지난 시즌보다<br>
                    크기가 줄었어요! (1박스 높이 60cm)
                  </div>
                </div>
                <div class="p small">
                  <span class="symbol">•</span>
                  <span class="content">단체 4개 (혹은6개) 제공</span>
                </div>
              </div>
              <div class="box-index">
                <span class="index-name">2</span>
                제로웨이스트 박스
              </div>
              <div class="card-content">
                <div class="p">
                  <span class="symbol">-</span>
                  <span class="content">
                    개인 1인당 2박스 제공
                  </span>
                </div>
                <div class="p">
                  <span class="symbol">-</span>
                  <span class="content">단체 1팀당 4박스 제공</span>
                </div>
                <div class="p small">
                  <span class="symbol">•</span>
                  <div class="content">
                    단체 1팀당 4박스 제공 (단체의 경우, 구성원 규모를 고려하여<br>
                    최대 6박스까지 신청 가능합니다. 추후 캠페인 참가에 선정된<br>
                    팀은 추가 박스가 필요할 경우 [원더플 캠페인] 카카오톡 채널을<br>
                    통해 접수 부탁드립니다.)
                  </div>
                </div>
              </div>
            </div>
            <div class="tip">
              * 선정된 분에게는 개별 카카오톡 메시지가 발송되며, <br>
              단체의 경우 대표자로 신청해주신 분의 연락처로 발송됩니다.
            </div>
          </div>

          <div class="step-card-container step-card-container-3">
            <div class="step-card step-3">
              <div class="step-name">
                STEP 3.
              </div>
              <div class="card-name">
                <span class="strong">투명 음료</span>&nbsp;페트병 모으기
              </div>
              <div class="card-content">
                <div class="p">
                  <span class="symbol">1</span>
                  <div class="content">
                    투명 음료 페트병을 깨끗한 물로 헹구고<br>
                    라벨을 제거해주세요.
                  </div>
                </div>
              </div>
              <div class="card-content">
                <div class="p">
                  <span class="symbol">2</span>
                  <div class="content">
                    깨끗하게 씻은 투명 음료 페트병은 잘 건조 <br>시켜주세요.
                  </div>
                </div>
              </div>
              <div class="card-content">
                <div class="p">
                  <span class="symbol">3</span>
                  <span class="content">최대한 압착한 뒤 뚜껑을 닫아주세요.</span>
                </div>
              </div>
              <div class="card-tip white-space-normal">
                * 올바른 방법으로 참여하지 않으실 경우, <br class="d-block d-md-none">
                리워드 제공 대상에서 제외될 수 있습니다.
              </div>
            </div>
          </div>

          <div class="step-card-container step-card-container-4">
            <div class="step-card step-4">
              <div class="step-name">
                STEP 4.
              </div>
              <div class="card-name">
                <span class="strong">수거 신청</span>
              </div>
              <div class="card-content">
                <div class="p">
                  <span class="symbol">1</span>
                  <div class="content">
                    <div>
                      제로웨이스트 박스를 포장한 비닐을 박스에 <br>
                      넣은 뒤 투명 음료 페트병을 채워주세요.
                    </div>
                    <div class="box-strong">
                      <div class="strong">
                        * 비닐도 모두 재활용 됩니다
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-content">
                <div class="p">
                  <span class="symbol">2</span>
                  <div class="content">
                    <div>박스가 열리지 않도록 테이프로</div>
                    <div>밀봉해주세요.</div>
                  </div>
                </div>
              </div>
              <div class="card-content">
                <div class="p">
                  <span class="symbol">3</span>
                  <div class="content">
                    <div>
                      박스 상단 QR 코드 or 원더플 캠페인
                    </div>
                    <div>
                      사이트를 통해 수거 신청을 완료해주세요.
                    </div>
                    <div class="box-strong">
                      <div class="strong">
                        <span class="symbol">*</span>
                        <span class="content">2박스 모두 신청할 경우 두 번 수거 신청을 해 주셔야 해요.</span>
                      </div>
                      <div class="strong">
                        <span class="symbol">*</span>
                        <div class="content">
                          개인 참여자의 경우 2박스 모두, 단체의 경우 <br class="d-block d-md-none">
                          4박스(혹은 6박스)를 <br class="d-none d-md-block">모두 신청해주셔야 해요.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-content">
                <div class="p">
                  <span class="symbol">4</span>
                  <span class="content">
                    수거 신청 완료 후 박스를 현관 문 앞에
                    <br>
                    놓아주세요. 접수일로부터 3~4일 이내 택배
                    <br>
                    기사님께서 방문하여 수거할 예정입니다.
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="step-card-container step-card-container-5">
            <div class="step-card step-5">
              <div class="step-name">
                STEP 5.
              </div>
              <div class="card-name">
                리워드&nbsp;<span class="strong">수령</span>
              </div>
              <div class="card-content">
                <div class="p">
                  <span class="symbol">1</span>
                  <div class="content">
                    여러분이 보내주신 제로웨이스트 박스는
                    <br class="d-block d-md-none">
                    글로벌 재활용 전문 기업 테라사이클을 통해 <br>
                    박스와 내용물 모두 재활용됩니다.
                  </div>
                </div>
              </div>
              <div class="card-content">
                <div class="p">
                  <span class="symbol">2</span>
                  <div class="content">
                    테라사이클로 도착한 제로웨이스트 박스 <br class="d-block d-md-none">
                    검수 후 올바른 방법으로 참여한 원더플 피플<br class="d-block d-md-none">을 <br class="d-none d-md-block">위한 리워드를 보내 드립니다.
                    <div class="box-strong">
                      <div class="strong">
                        &lt;필수>
                      </div>
                      <div class="strong">
                        <span class="symbol">-</span>
                        <div class="content">
                          개인의 경우 2박스, 단체의 경우 4박스(혹은 6박스) 모두 <br class="d-block d-md-none">
                          올바른 방법으로 수거 및 검수가 되어야 해요!
                        </div>
                      </div>
                      <div class="strong">
                        <span class="symbol">-</span>
                        <div class="content">
                          입고 및 검수 과정은 모두 수작업으로 진행되다 보니 <br class="d-block d-md-none">
                          약 2~3주까지 소요될 수 있어요. 조금만 기다려주세요.
                        </div>
                      </div>
                      <div class="strong">
                        <span class="symbol">-</span>
                        <div class="content">
                          투명 음료 페트병이 아닌 수거품을 보낸 분은 개별 안내 없이 <br class="d-block d-md-none">
                          리워드 수령 대상에서 제외 될 수 있습니다.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-content">
                <div class="p">
                  <span class="symbol">3</span>
                  <div class="content">
                    곧 원더플 피플에게 찾아갈 리워드와 함께 <br class="d-block d-md-none">
                    여러분의 일상 또한 원더플 해지길 <br class="d-block d-md-none">
                    바랍니다.
                    <div class="box-strong">
                      <div class="strong">
                        <span class="symbol">-</span>
                        <div class="content">
                          수거 신청 마감 일정을 준수해 주셔야 하며, 기간 이후 <br class="d-block d-md-none">
                          신청의 경우 수거가 어려운 점 양해 부탁드립니다.
                        </div>
                      </div>
                      <div class="strong">
                        <span class="symbol">-</span>
                        <div class="content">
                          리워드 미제공 대상자(탈락자)의 경우 마이페이지 상에서 <br class="d-block d-md-none">
                          확인이 가능하며, 별도의 안내 메시지가 발송되지 않습니다.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
  @import '../../vars.scss';

  .page-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 vwMobile(15px) vwMobile(45px);
    background-image: url('./images/bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #fdb515;

    @include media-breakpoint-up(xl) {
      > .container {
        max-width: vw(1208px);
      }
    }

    @include media-breakpoint-up(md) {
      padding: 0 0 vw(144px);
      background-image: url('./images/bg-pc.png');
      background-size: cover;
    }

    .section-title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: vwMobile(240px);
      height: vwMobile(36px);
      margin: 0 auto;
      border-radius: vwMobile(18px);
      font-size: vwMobile(18px);
      font-family: ImcreSoojin;
      text-align: center;
      color: #fff;
      background: #fda30f;

      @include media-breakpoint-up(md) {
        width: vw(500px);
        height: vw(89px);
        border-radius: vw(50px);
        font-size: vw(46px);
      }

      span:last-child {
        color: $black;
      }
    }

    .section-3-1 {
      .section-title {
        margin-top: vwMobile(53px);
        margin-bottom: vwMobile(20px);

        @include media-breakpoint-up(md) {
          margin-top: vw(102px);
          margin-bottom: vw(135px);
        }
      }

      .box-cycle {
        @include media-breakpoint-up(md) {
          display: flex;
        }

        .cycle-card {
          position: relative;
          margin-top: vwMobile(40px);

          @include media-breakpoint-up(md) {
            flex: 1;
            flex-shrink: 0;
            padding: vw(33px) vw(16px) vw(82px) vw(32px);
            margin-top: 0;
            border-radius: vw(36px);
            background: #fafafa;
            box-shadow: vw(8px) vw(10px) vw(20px) rgba(253, 181, 21, 0.44);

            &:not(:last-child) {
              margin-right: vw(30px);
            }
          }

          .card-title {
            margin-bottom: vwMobile(10px);
            font-size: vwMobile(16px);
            font-family: ImcreSoojin;
            text-align: center;
            color: $black;

            @include media-breakpoint-up(md) {
              margin-bottom: vw(19px);
              font-size: vw(30px);
              line-height: 1.93;
              text-align: left;
            }
          }

          .card-content {
            padding: vwMobile(23px) vwMobile(20px) vwMobile(23px) vwMobile(45px);
            border-radius: vwMobile(15px);
            background: #fafafa;
            box-shadow: vwMobile(8px) vwMobile(10px) vwMobile(20px) rgba(253, 181, 21, 0.44);

            @include media-breakpoint-up(md) {
              padding: 0;
              border-radius: 0;
              background: transparent;
              box-shadow: none;
            }

            .item {
              display: flex;
              align-items: center;
              font-size: vwMobile(16px);
              font-family: AppleSDGothicNeoB;
              letter-spacing: -0.03em;
              color: #515151;

              @include media-breakpoint-up(md) {
                font-size: vw(20px);
                line-height: 1.85;
              }

              &:not(:last-child) {
                margin-bottom: vwMobile(20px);

                @include media-breakpoint-up(md) {
                  margin-bottom: vw(42px);
                }
              }

              span {
                &:first-child {
                  min-width: vwMobile(80px);
                  margin-right: vwMobile(22px);
                  font-family: ImcreSoojin;
                  color: $red;

                  @include media-breakpoint-up(md) {
                    min-width: vw(96px);
                    margin-right: vw(10px);
                  }
                }
              }
            }
          }
        }
      }

      .notice {
        margin-top: vwMobile(41px);

        @include media-breakpoint-up(md) {
          margin-top: vw(50px);
        }

        .notice-title {
          display: flex;
          justify-content: center;
          align-items: center;
          width: vwMobile(118px);
          height: vwMobile(36px);
          margin: 0 auto;
          margin-bottom: vwMobile(9px);
          border-radius: vwMobile(18px);
          font-size: vwMobile(16px);
          font-family: ImcreSoojin;
          color: #fff;
          background: $red;

          @include media-breakpoint-up(md) {
            width: vw(119px);
            height: vw(40px);
            margin-bottom: vw(40px);
            border-radius: vw(22px);
            font-size: vw(23px);
          }
        }

        .notice-content {
          font-size: vwMobile(14px);
          line-height: 2;
          text-align: center;
          letter-spacing: -0.03em;
          color: $black;

          @include media-breakpoint-up(md) {
            font-size: vw(18px);
          }

          p {
            margin: 0;
          }
        }
      }
    }

    .section-3-2 {
      margin-top: vwMobile(110px);

      @include media-breakpoint-up(md) {
        margin-top: vw(186px);
      }

      .box-step {
        @include media-breakpoint-up(md) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0 vw(22px);
        }

        .step-card-container {
          margin-top: vwMobile(85px);

          @include media-breakpoint-up(md) {
            position: relative;
            width: 48.7%;
            margin-top: vw(160px);
          }
        }

        .step-card-container-5 {
          @include media-breakpoint-up(md) {
            width: 100%;

            .step-card.step-5 {
              padding-top: vw(142px);
              padding-bottom: vw(65px);

              .card-name {
                margin-bottom: vw(80px);
              }

              .card-content {
                &:not(:first-child) {
                  padding-top: vw(38px);
                  padding-right: vw(66px);
                  padding-bottom: vw(36px);
                  padding-left: vw(66px);
                  margin-top: vw(30px);
                }

                > .p .box-strong {
                  .strong {
                    line-height: 2;
                  }
                }
              }
            }
          }
        }

        .tip {
          padding: 0;
          margin-top: vwMobile(20px);
          font-size: vwMobile(12px);
          line-height: 1.66;
          text-align: center;
          letter-spacing: -0.03em;

          @include media-breakpoint-up(md) {
            position: absolute;
            bottom: vw(-22px);
            left: 0;
            padding: 0 vw(26px);
            margin-top: 0;
            font-size: vw(16px);
            line-height: 1.56;
            text-align: left;
            transform: translateY(100%);
          }
        }

        .step-card {
          position: relative;
          padding: vwMobile(78px) vwMobile(17px) vwMobile(31px);
          border-radius: vwMobile(36px);
          white-space: nowrap;
          background: #fafafa;
          box-shadow: vwMobile(8px) vwMobile(10px) vwMobile(20px) rgba(253, 181, 21, 0.44);

          @include media-breakpoint-up(md) {
            height: 100%;
            padding: vw(111px) vw(26px) vw(43px);
            border-radius: vw(36px);
            box-shadow: vw(8px) vw(10px) vw(20px) rgba(253, 181, 21, 0.44);
          }

          .step-name {
            position: absolute;
            top: 0;
            left: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: vwMobile(90px);
            height: vwMobile(90px);
            border-radius: 50%;
            font-size: vwMobile(16px);
            font-family: ImcreSoojin;
            color: #fff;
            background-color: $red;
            transform: translate(-50%, -50%);

            @include media-breakpoint-up(md) {
              width: vw(144px);
              height: vw(144px);
              font-size: vw(26px);
            }
          }

          .card-name {
            display: flex;
            align-items: center;
            margin-bottom: vwMobile(35px);
            font-size: vwMobile(16px);
            font-family: ImcreSoojin;
            letter-spacing: -0.02em;

            @include media-breakpoint-up(md) {
              margin-bottom: vw(44px);
              font-size: vw(30px);
            }

            .strong {
              color: $red;
            }

            &:before,
            &:after {
              flex: 1;
              height: 1px;
              background-color: #231f20;
              content: '';
            }

            &:before {
              margin-right: vwMobile(14px);

              @include media-breakpoint-up(md) {
                margin-right: vw(13px);
              }
            }

            &:after {
              margin-left: vwMobile(14px);

              @include media-breakpoint-up(md) {
                margin-left: vw(13px);
              }
            }
          }

          .box-index {
            display: flex;
            align-items: center;
            margin-top: vwMobile(27px);
            margin-bottom: vwMobile(10px);
            font-size: vwMobile(16px);
            font-family: ImcreSoojin;
            color: $red;

            @include media-breakpoint-up(md) {
              margin-top: vw(60px);
              margin-bottom: vw(36px);
              font-size: vw(26px);
            }

            .index-name {
              display: flex;
              justify-content: center;
              align-items: center;
              width: vwMobile(22px);
              height: vwMobile(22px);
              margin-right: vwMobile(5px);
              border-radius: 50%;
              font-size: vwMobile(16px);
              font-family: AppleSDGothicNeoB;
              line-height: normal;
              color: #fff;
              background-color: $red;

              @include media-breakpoint-up(md) {
                width: vw(36px);
                height: vw(36px);
                margin-right: vw(8px);
                font-size: vw(26px);
              }
            }
          }

          .card-content {
            padding: vwMobile(16px) 0 vwMobile(14px) vwMobile(16px);
            border-radius: vwMobile(16px);
            font-size: vwMobile(16px);
            letter-spacing: -0.01em;
            background-color: #fff;

            @include media-breakpoint-up(md) {
              padding: vw(30px) 0 vw(30px) vw(40px);
              border-radius: vw(16px);
              font-size: vw(26px);
            }

            > .p:not(.small) {
              font-family: AppleSDGothicNeoM;
            }

            .p {
              display: flex;
              margin-bottom: vwMobile(3px);
              line-height: 1.625;

              @include media-breakpoint-up(md) {
                margin-bottom: vw(5px);
                line-height: 1.84;
              }

              .symbol {
                flex-shrink: 0;
                margin-right: vwMobile(3px);

                @include media-breakpoint-up(md) {
                  margin-right: vw(5px);
                }
              }

              .content {
                flex: 1;
              }

              &.small {
                padding-left: vwMobile(5px);
                font-size: vwMobile(12px);
                line-height: 1.66;
                letter-spacing: -0.05em;
                color: #515151;

                @include media-breakpoint-up(md) {
                  padding-left: vw(5px);
                  font-size: vw(16px);
                  line-height: 1.56;
                }
              }
            }
          }

          .card-tip {
            margin-top: vwMobile(21px);
            font-size: vwMobile(12px);
            line-height: 1.66;
            text-align: center;
            letter-spacing: -0.03em;
            color: $red;

            @include media-breakpoint-up(md) {
              margin-top: vw(20px);
              font-size: vw(16px);
              line-height: 1.625;
              text-align: left;
            }
          }
        }

        .step-2 {
          .card-content.has-img {
            position: relative;

            .img-product {
              position: absolute;
              bottom: 0;
              right: vwMobile(-10px);
              width: vwMobile(83px);
              height: auto;
              transform: translateY(40%);

              @include media-breakpoint-up(md) {
                right: vw(-10px);
                width: vw(126px);
                transform: translateY(0%);
              }
            }
          }
        }

        .step-3,
        .step-4,
        .step-5 {
          padding-bottom: vwMobile(21px);

          @include media-breakpoint-up(md) {
            padding-bottom: vw(56px);
          }

          .card-name {
            margin-bottom: vwMobile(16px);

            @include media-breakpoint-up(md) {
              margin-bottom: vw(40px);
            }
          }

          .card-content {
            padding: vwMobile(12px) 0 vwMobile(11px) vwMobile(8px);
            line-height: 1.62;

            @include media-breakpoint-up(md) {
              padding: vw(13px) 0 vw(16px) vw(14px);
              line-height: 1.5;
            }

            &:not(:first-child) {
              margin-top: vwMobile(5px);

              @include media-breakpoint-up(md) {
                margin-top: vw(17px);
              }
            }

            > .p {
              font-size: vwMobile(16px);
              font-family: AppleSDGothicNeoM;
              line-height: vwMobile(26px);

              @include media-breakpoint-up(md) {
                font-size: vw(26px);
                line-height: 1.53;
              }

              > .symbol {
                display: flex;
                justify-content: center;
                align-items: center;
                width: vwMobile(22px);
                height: vwMobile(22px);
                margin-top: vwMobile(3px);
                margin-right: vwMobile(8px);
                border-radius: 50%;
                font-family: AppleSDGothicNeoB;
                line-height: normal;
                color: #fff;
                background-color: #000000;

                @include media-breakpoint-up(md) {
                  width: vw(36px);
                  height: vw(36px);
                  margin-top: vw(2px);
                  margin-right: vw(15px);
                }
              }

              .box-strong {
                margin-top: vwMobile(8px);

                @include media-breakpoint-up(md) {
                  margin-top: vw(10px);
                }

                .strong {
                  display: flex;
                  font-size: vwMobile(12px);
                  font-family: AppleSDGothicNeoR;
                  line-height: 1.83;
                  letter-spacing: -0.06em;
                  color: $red;

                  @include media-breakpoint-up(md) {
                    font-size: vw(16px);
                    line-height: 1.62;
                  }

                  .symbol {
                    flex-shrink: 0;
                    margin-right: vwMobile(2px);

                    @include media-breakpoint-up(md) {
                      margin-right: 2px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
