export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Collection request has been completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection request has been completed"])},
      "Program Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Information"])},
      "Program Submission day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Submission day"])},
      "Type of Participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Participation"])},
      "Status of application for activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status of application for activities"])},
      "My address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My address"])},
      "Collection Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection Information"])},
      "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
      "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
      "Tracking number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking number"])},
      "Date created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date created"])},
      "Date received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date received"])},
      "Points earned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points earned"])},
      "Status of colletion shippment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status of colletion shippment"])},
      "Confirm cancellation?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm cancellation?"])},
      "Confirm request?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm request?"])},
      "No collection application record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No collection application record"])},
      "Rounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rounds"])}
    }
  })
}
