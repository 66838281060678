export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
      "Account information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account information"])},
      "SCREEN NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCREEN NAME"])},
      "FIRST NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIRST NAME"])},
      "LAST NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LAST NAME"])},
      "NICKNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NICKNAME"])},
      "ADDRESS 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADDRESS 1"])},
      "ADDRESS 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADDRESS 2"])},
      "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "ZIP CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZIP CODE"])},
      "PHONE NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHONE NUMBER"])},
      "nicknameTip": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This nickname will be visible on our contest leaderboard. See our"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
        
      ],
      "first_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["John"])},
      "last_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doe"])},
      "nick_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["John Doe"])},
      "address_1_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street address"])},
      "address_2_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extended address"])},
      "state_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
      "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trenton"])}
    }
  })
}
