export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "request_collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回收物を送る(集荷依賴)"])},
      "program_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳しくは"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提出日"])},
      "close_confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このプログラムから退会することを確認する"])},
      "request_offline_collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収物を送る"])},
      "collection_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リサイクルチャレンジ"])}
    }
  })
}
