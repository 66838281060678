export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "AGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나이"])},
      "GENDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성별"])},
      "HOW DID YOU HEAR ABOUT US?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테라사이클을 알게된 경로가 무엇인가요??"])},
      "ORGANIZATION NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단체명"])},
      "ORGANIZATION TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단체 유형"])},
      "PEOPLE COLLECTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 참여자 수"])},
      "organization_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단체명"])},
      "people_collection_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0보다 큰 숫자를 입력해주세요"])}
    }
  })
}
