import { authStore } from '@/stores';

interface IOAuthProvider {
  name: string;
  url: string;
  connect: boolean;
  click: () => void;
}

export function getOAuthProviderByLocale(locale: string) {
  return [
    locale !== 'ja' && { name: 'kakao', url: require('@/assets/icon/kakaotalk.png'), connect: false, click: () => { authStore.goKakaoOauth(); } },
    locale !== 'ko' && { name: 'line', url: require('@/assets/icon/line.png'), connect: false, click: () => { authStore.goLineOauth(); } },
    locale !== 'ja' && { name: 'naver', url: require('@/assets/icon/naver.png'), connect: false, click: () => { authStore.goNaverOauth(); } },
    { name: 'google', url: require('@/assets/icon/google.png'), connect: false, click: () => { authStore.goGoogleOauth(); } },
  ].filter(Boolean) as IOAuthProvider[];
};
