import { request } from '@/utils';
import { SimpleStore } from './helper/simple-store';
import { TPurchaseKind } from '@/types/product';
import { IShoppingCartItem, IShoppingCart } from '@/types/shopping-cart';
import _ from 'lodash';

export class ShoppingCartStore extends SimpleStore {

  data: IShoppingCartItem[] = [];
  checkedIds: number[] = [];

  get allActiveItemIds() {
    return this.data.filter(item => item.active).map(item => item.id);
  }

  get allChecked() {
    return Boolean(this.checkedIds.length && (this.checkedIds.length === this.allActiveItemIds.length));
  }

  get checkedQuantity() {
    return _.sumBy(this.getCheckedItems(), 'quantity');
  }

  get totalQuantity() {
    return _.sumBy(this.data, 'quantity');
  }

  async fetch() {
    const { data: { shopping_cart_items } } = await request.get<IShoppingCart>('/bean/shopping_cart');
    this.data = shopping_cart_items;
  }

  getCheckedItems(): IShoppingCartItem[] {
    return this.data.filter(item => this.checkedIds.includes(item.id));
  }

  /**
   * 添加到购物车
   * @param variantId 商品id
   * @param purchaseKind 购买方式
   * @param quantity 数量
   */
  async addToCart(variantId: number, purchaseKind: TPurchaseKind, quantity: number) {
    const { data } = await request.post<IShoppingCartItem>('/bean/shopping_cart_items', {
      variant_id: variantId,
      purchase_kind: purchaseKind,
      quantity
    });
    const cartItem = this.data.find(item => item.id === data.id);
    if (cartItem) {
      Object.assign(cartItem, data);
    } else {
      this.data.unshift(data);
    }
  }

  /**
   * 更新购物车item数量
   * @param id 购物车item id
   * @param quantity 数量
   */
  async updateCartItemQuatity(id: number, quantity?: number) {
    if (_.isNumber(quantity) && (quantity <= 0)) {
      return this.deleteCartItem(id);
    }
    const cartItem = this.data.find(item => item.id === id);
    if (cartItem) {
      const { data } = await request.put<IShoppingCartItem>(`/bean/shopping_cart_items/${id}`, {
        quantity: _.isUndefined(quantity) ? cartItem.quantity : quantity
      }, { loading: { silent: true } });
      if (cartItem) {
        Object.assign(cartItem, data);
      }
    }
  }

  /**
   * 删除购物车item
   * @param id 购物车item id
   */
  async deleteCartItem(id: number) {
    await request.delete<IShoppingCartItem>('/bean/shopping_cart_items', { params: { id } });
    this.data = this.data.filter(item => item.id !== id);
  }

  /**
   * 切换购物车item的选中状态
   * @param id 购物车item id
   */
  toggleCartItemChecked(id: number) {
    const index = this.checkedIds.findIndex(item => item === id);
    if (index === -1) {
      this.checkedIds.push(id);
    } else {
      this.checkedIds.splice(index, 1);
    }
  }

  toggleCartItemCheckAll() {
    if (this.allChecked) {
      this.checkedIds = [];
    } else {
      this.checkedIds = [...this.allActiveItemIds];
    }
  }
}

export const shoppingCartStore = ShoppingCartStore.create<ShoppingCartStore>();
