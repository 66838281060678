export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信する"])},
      "CAPTCHA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAPTCHA (右に表示された文字を入力してください）"])},
      "Captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["右に表示された文字を入力してください"])}
    }
  })
}
