import { BaseType, getBaseData } from './base';
import { IProgram } from './program';

export interface PDOType extends BaseType {
  facility_name: string;
  postal_code: string;
  street_address: string;
  extended_address: string;
  phone_number: string;
  opening_time: string;
  geographic_coordinates: string;
  program_id?: number;
  collection_method: string;
  program?: IProgram;
  show_phone_on_map?: boolean;
}

export function getPdoDefaultData(): PDOType {
  return {
    ...getBaseData(),
    facility_name: '',
    postal_code: '',
    street_address: '',
    extended_address: '',
    phone_number: '',
    opening_time: '',
    geographic_coordinates: '',
    collection_method: ''
  };
}
