export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Sign Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインアウト"])},
      "Sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン"])},
      "Search programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プログラムを探す"])}
    }
  })
}
