export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "available_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용 시간"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신규"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만료됨"])}
    }
  })
}
