import { Collection } from './helper/collection';
import { IArticle } from '@/types/article';
import { request } from '@/utils';

export class FaqSectionArticlesStore extends Collection {
  data: IArticle[] = [];
  id: number | undefined;
  keyword: string | undefined;

  fetch(params = {}) {
    return request.get('/articles', { params: { keyword: this.keyword, type_eq: 'FaqArticle', faq_section_id_eq: this.id, ...params } });
  }
}
