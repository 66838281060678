export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유"])},
      "viewsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["조회수 ", _interpolate(_named("count")), "회"])},
      "writing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["글"])},
      "shareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테라사이클의 이야기를\n공유해보세요"])},
      "copySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL이 복사되었습니다."])}
    }
  })
}
