export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "available_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Time"])},
      "go_shopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go shopping"])},
      "coupon_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon Details"])},
      "make_this_coupon_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make this coupon used"])},
      "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon will be invalid if you click the button before purchase."])},
      "make_this_coupon_used_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make this coupon used?"])}
    }
  })
}
