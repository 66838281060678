export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "toogleHasContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ToggleContainer"])},
      "moveUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up"])},
      "moveDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Down"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])}
    }
  })
}
