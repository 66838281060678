export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["始める"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プログラムに参加する"])}
    }
  })
}
