export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Collection Bin Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거함 관리"])},
      "Create Bin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거함 생성하기"])},
      "Created at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성 일시"])},
      "Bin Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거함명"])}
    }
  })
}
