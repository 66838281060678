export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Please confirm you are 13 years of age or older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13才以上です。"])},
      "Sign up to receive updates about TerraCycle and our programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テラサイクルの最新情報を受け取ります！"])},
      "agree": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["規約に同意します。弊社の"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["及び"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシーポリシー"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["をご覧ください。"])},
        
      ],
      "agree_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約とプライバシーポリシーに同意し、テラサイクルからの最新情報を受け取ることに同意します。"])}
    }
  })
}
