export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Contact information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先"])},
      "Additional information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加情報"])},
      "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のパスワード"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])}
    }
  })
}
