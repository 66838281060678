export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "TerraCycle Recycling Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테라사이클 재활용 캠페인 리워드"])},
      "Your current have:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 보유 포인트 :"])},
      "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트"])},
      "Redeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환하기"])},
      "Redeem points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트 교환하기"])},
      "Add new charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 기부 추가하기"])},
      "Please fill in the points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트를 입력해주세요"])},
      "Not enough points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보유 포인트가 부족합니다"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리뷰"])},
      "Search for an existing charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for an existing charity"])},
      "Current Points Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Points Balance"])}
    }
  })
}
