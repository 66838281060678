import { request } from '@/utils';
import { Collection } from './helper/collection';

type Params = {
  per_page: number;
  type_eq: string;
  state_eq: undefined | number | string;
  target_name_cont?: string
}

const params: Params = {
  type_eq: 'ParticipationRequest',
  state_eq: undefined,
  per_page: 5
};

class UserRequestStore extends Collection {
  async fetch(params: Params) {
    return request.get('/user_requests', { params });
  }
}

export const userRequestStore = new UserRequestStore({ params });
