export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Redeem my points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트 사용하기"])},
      "Redeem history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트 사용 내역"])},
      "Your current have:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 보유 포인트 :"])},
      "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트"])},
      "Favorite charities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관심 목록에 추가된 기부재단"])},
      "texts": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 추가한 기부 선물이 없습니다"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트 기부 자세히 알아보기"])},
        
      ]
    }
  })
}
