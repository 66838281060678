export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
      "Forgot your ID?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your ID?"])}
    }
  })
}
