export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "step": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["단계 ", _interpolate(_named("index"))])},
      "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거품목 QR 코드 스캔"])},
      "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품 정보 업로드 후에 수거함에 수거품목을 넣어주세요"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])},
      "add_more_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 품목 추가하기"])},
      "collection_bin_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<span class=\"strong\">", _interpolate(_named("name")), "</span>을(를) 접수 중인 수거함입니다"])},
      "remaining_number_tips": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("num")), "개의 수거 품목을 추가 접수할 수 있습니다."])},
      "scan_tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 품목의 제품 바코드를 스캔하려면 클릭하세요. 모바일 디바이스의 카메라가 스캔에 실패할 경우 바코드 아래의 숫자를 직접 입력해주세요"])}
    }
  })
}
