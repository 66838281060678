export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Please confirm you are 14 years of age or older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["귀하의 나이가 만 14세 이상인지 확인하십시오."])},
      "Sign up to receive updates about TerraCycle and our programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테라사이클과 재활용 캠페인에 대한 소식을 구독합니다"])},
      "agree": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용약관을 읽었으며 이에 동의합니다. 보기"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용약관"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["및"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보처리방침"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 브라우저 창에서"])},
        
      ],
      "agree_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용 약관, 개인정보 수집 및 이용, 광고성 정보 수신(선택)에 모두 동의합니다"])}
    }
  })
}
