export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Contact information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
      "Email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "First name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "Zip code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
      "Last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "Nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
      "Phone number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "Age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
      "Organization name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization name"])},
      "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "Organization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization type"])},
      "People collecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People collecting"])},
      "Edit Your Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Your Profile"])},
      "Connect your account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your account"])},
      "Connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
      "Connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
      "Street Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADDRESS 1"])},
      "Extended Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADDRESS 2"])}
    }
  })
}
