export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "remaining_number_tips": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("num")), "개의 수거 품목을 추가 접수할 수 있습니다."])},
      "participate_join_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 사용자는 재활용 프로젝트에 참여하지 않았습니다. 참여하도록 도와주시겠습니까?"])},
      "participate_join_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성공적으로 참여."])}
    }
  })
}
