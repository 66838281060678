export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Search programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["団体名、市区町村や都道府県で検索する"])},
      "Redeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用する"])}
    }
  })
}
