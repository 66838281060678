<template>
  <b-container
    class="toast-container"
    :toast="{root: true}"
    fluid="sm"
    position="position-fixed"
  />
  <router-view />
  <!-- 给teleport用 -->
  <div id="app-footer" />
</template>
<style lang="scss" scoped>
  .toast-container {
    z-index: z('toast');

    :deep(.toast) {
      white-space: pre-wrap;
    }
  }
</style>
