export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "PHONE NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
      "CAPTCHA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAPTCHA"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録する"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find ID (e-mail address)"])},
      "my_id_is": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID (e-mail address): ", _interpolate(_named("id"))])}
    }
  })
}
