export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テラサイクルジャパンヘルプセンターへようこそ！"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問してください!"])}
    }
  })
}
