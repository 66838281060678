<template>
  <div class="page-6">
    <div class="container">
      <h6 class="section-title">
        재활용 공정 프로세스
      </h6>
      <p class="section-desc">
        원더플 피플이 보내준 투명 음료 페트병은 이렇게 재활용됩니다.
      </p>
      <p class="section-desc">
        짧은 시간 쓰이고 버려지는 플라스틱이지만
        <br class="d-block d-md-none">
        재활용 공정에는 긴 시간이 걸려요.
      </p>
      <p class="section-desc">
        재활용 공정은 수거부터 원료화까지 모든 과정이 연결되어 있어요.
      </p>
      <p class="section-desc">
        잘 재활용되기 위해서는 기다림의 시간이 필요하답니다!
      </p>
    </div>

    <div class="container-steps">
      <div class="container">
        <div class="box-steps">
          <div class="step-card step-1">
            <div class="step-header">
              <div class="step-info">
                <div class="step-index">
                  STEP 1.
                </div>
                <div class="step-name">
                  방문 수거
                </div>
              </div>
              <img :src="require('./images/step-1.png')">
            </div>
            <div class="step-content white-space-no-wrap">
              <div class="d-block d-md-none">
                원더플 피플이 지정하신 장소로 방문해<br>
                제로웨이스트 박스를 수거해 갑니다.
              </div>
              <div class="d-none d-md-block">
                원더플 피플이 지정하신 장소로<br>
                방문해 제로웨이스트 박스를<br>
                수거해 갑니다.
              </div>
            </div>
          </div>
          <div class="step-card step-2">
            <div class="step-header">
              <div class="step-info">
                <div class="step-index">
                  STEP 2.
                </div>
                <div class="step-name">
                  분류/세척
                </div>
              </div>
              <img :src="require('./images/step-2.png')">
            </div>
            <div class="step-content white-space-no-wrap">
              <div class="d-none d-md-block">
                투명 음료 페트병이 고품질 자원이<br>
                될 수 있도록 분류 및 세척 과정을<br>
                거칩니다.
              </div>
              <div class="d-block d-md-none">
                투명 음료 페트병이 고품질 자원이 될 수 있도록<br>
                분류 및 세척 과정을 거칩니다.
              </div>
            </div>
          </div>
          <div class="step-card step-3">
            <div class="step-header">
              <div class="step-info">
                <div class="step-index">
                  STEP 3.
                </div>
                <div class="step-name">
                  재활용 공정
                </div>
              </div>
              <img :src="require('./images/step-3.png')">
            </div>
            <div class="step-content white-space-no-wrap">
              <div class="d-none d-md-block">
                테라사이클을 통해<br>
                재활용 공정 과정을 거쳐<br>
                새로운 자원이 될 준비를 합니다.
              </div>
              <div class="d-block d-md-none">
                테라사이클을 통해 재활용 공정 과정을 거쳐<br>
                새로운 자원이 될 준비를 합니다.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style scoped lang="scss">
  @import '../../vars.scss';

  .page-6 {
    padding-top: vwMobile(65px);

    @include media-breakpoint-up(md) {
      padding-top: vw(105px);
    }
  }

  .section-title {
    margin-bottom: vwMobile(30px);
    font-size: vwMobile(18px);
    font-family: ImcreSoojin;
    line-height: 1.67;
    text-align: center;
    letter-spacing: -0.02em;
    color: $red;

    @include media-breakpoint-up(md) {
      margin-bottom: vw(50px);
      font-size: vw(46px);
    }
  }

  .section-desc {
    padding: 0 vwMobile(12px);
    margin: 0;
    font-size: vwMobile(12px);
    line-height: 2;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: vw(24px);
      line-height: 1.91;
    }
  }

  .container-steps {
    background: #fdb515;
  }

  .box-steps {
    display: flex;
    padding-top: vwMobile(50px);
    padding-bottom: vwMobile(46px);
    margin-top: vwMobile(29px);
    overflow: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(md) {
      padding-top: vw(143px);
      padding-bottom: vw(166px);
      margin-top: vw(109px);
    }
  }

  .step-card {
    flex-shrink: 0;
    width: vwMobile(330px);
    height: vwMobile(250px);
    padding-right: vwMobile(30px);
    padding-left: vwMobile(30px);
    margin-right: vwMobile(11px);
    border-radius: vwMobile(36px);
    background: #fff;
    box-shadow: vwMobile(3px) vwMobile(4px) vwMobile(22px) rgba(145, 145, 145, 0.25);

    @include media-breakpoint-up(md) {
      flex: 1;
      height: unset;
      padding-right: vw(39px);
      padding-bottom: vw(25px);
      padding-left: vw(39px);
      margin-right: vw(11px);
      border-radius: vw(36px);
      box-shadow: vw(3px) vw(4px) vw(22px) rgba(145, 145, 145, 0.25);

      &:not(:last-child) {
        margin-right: vw(30px);
      }
    }

    @include media-breakpoint-down(md) {
      &:first-child {
        margin-left: vwMobile(16px);
      }

      &:last-child {
        margin-right: vwMobile(16px);
      }
    }


    .step-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-top: vwMobile(22px);
      padding-bottom: vwMobile(22px);

      @include media-breakpoint-up(md) {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: vw(33px);
        padding-bottom: vw(16px);
      }

      .step-info {
        font-family: ImcreSoojin;
        letter-spacing: -0.02em;

        .step-index {
          font-size: vwMobile(14px);
          color: $red;

          @include media-breakpoint-up(md) {
            font-size: vw(24px);
            line-height: 1.1;
          }
        }

        .step-name {
          margin-top: vwMobile(7px);
          font-size: vwMobile(18px);

          @include media-breakpoint-up(md) {
            margin-top: vw(7px);
            font-size: vw(36px);
          }
        }
      }

      img {
        width: vwMobile(125px);
        height: auto;

        @include media-breakpoint-up(md) {
          width: vw(184px);
          margin-bottom: vw(44px);
        }
      }
    }

    .step-content {
      padding-top: vwMobile(15px);
      border-top: 1px solid #231f20;
      font-size: vwMobile(14px);
      line-height: 1.4;

      @include media-breakpoint-up(md) {
        padding-top: vw(19px);
        font-size: vw(24px);
        line-height: 1.66;
      }
    }
  }
</style>
