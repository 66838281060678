export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "btn_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["네"])},
      "address_check_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마이페이지에서 주소 및 전화 번호를 확인하세요"])},
      "address_check_dialog_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소 입력"])},
      "Cancel the collection request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 신청 취소"])},
      "Apply for participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여 신청"])},
      "Apply for collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 신청"])},
      "ready_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "이 될 준비 되셨나요?"])},
      "ready_dialog_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여자 분들께 함께 나가는 퀴즈!"])},
      "confirm_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료 하시겠습니까?"])},
      "participated_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 참여 하셨습니다."])},
      "cancel_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정말로 신청을 취소 하시겠습니까?"])},
      "cancel_dialog_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닫기 버튼을 누르면 캠페인 신청이 취소됩니다."])},
      "one_more_time_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한 번 더!"])},
      "one_more_time_dialog_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캠페인 참여자로 선정 시 마이페이지의 주소로 제로웨이스트박스를 보내드립니다."])},
      "completed_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캠페인 참여 신청이 완료 되었습니다."])},
      "completed_dialog_content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["반가워요, ", _interpolate(_named("name")), "님! 캠페인 참여자로 선정 시 마이페이지의 주소로 제로웨이스트박스를 보내드립니다.가 발송됩니다.수령지 주소 변경을 원하시면 아래 신청내력 확인 또는 주소 변경하기 버튼을 클릭해주세요."])},
      "completed_dialog_btn_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청 내역 확인 및 주소 정보 변경 하기"])},
      "completed_dialog_btn_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카카오톡 친구에게 공유하기"])},
      "apply_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "님 참여 해주셔서 감사합니다!"])},
      "participate_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "님, 참여 유형을 선택해주세요"])},
      "participate_together": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 가족 또는 친구와 참여하고 싶어요"])},
      "participate_alone": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 혼자 참여하고 싶어요"])},
      "apply_success_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 신청 접수가 완료 되었습니다. 마이페이지의 주소로 영업일 기준 2~3일 내 한진택배 기사님이 방문 예정입니다.할 예정입니다."])},
      "button_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청할게요"])},
      "butotn_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["괜찮아요"])},
      "verify_email_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 인증이 필요합니다"])},
      "verify_email_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 인증하기"])}
    }
  })
}
