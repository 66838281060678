export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加プログラム一覧に戻る"])},
      "Application Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プログラム参加日"])},
      "Application Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プログラム参加状況"])},
      "Offline Collection Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拠点回収履歴"])},
      "Date of Colletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録日"])},
      "Location Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収場所"])},
      "Location Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
      "Rewards Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン/ポイント"])},
      "Rewards Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認状況"])},
      "View Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン/ポイントみる"])},
      "Earned Points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得ポイント"])},
      "Barcode of the Collected Items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収物のバーコード"])},
      "View": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンを見る"])}
    }
  })
}
