import { Collection } from './helper/collection';
import { request } from '@/utils';
import { ITaxon } from '@/types/taxon';

export class TaxonListStore extends Collection {
  data: ITaxon[] = [];

  get selectOptions() {
    return [{ text: 'All product', value: '' }, ...this.data.map(item => ({ text: item.name, value: item.id }))];
  }

  fetch(params = {}) {
    return request.get('/bean/taxons', { params });
  }
}
