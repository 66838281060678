import { BaseType, getBaseData } from './base';
import { IImage } from './image';

interface ICharityCategory extends BaseType {
  title: string;
}

export interface ICharity extends BaseType {
  name: string;
  introduction: string;
  donate_points: number;
  image?: IImage;
  charity_category: ICharityCategory,
  favored: boolean;
}

export function getCharityDefaultData(): ICharity {
  return {
    ...getBaseData(),
    name: '',
    introduction: '',
    donate_points: 0,
    favored: false,
    charity_category: {
      ...getBaseData(),
      title: ''
    }
  };
}
