export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Basic information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 정보"])},
      "Email information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 연동"])},
      "Password information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 변경"])},
      "Delete Terracycle Account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정을 탈퇴 하시겠습니까?"])},
      "Successful authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증에 성공했습니다"])},
      "Delete confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["환경을 위해 테라사이클과 함께해주셔서 감사합니다. 정말 탈퇴 하시겠습니까?"])}
    }
  })
}
