export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Delete Terracycle Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Terracycle Account"])},
      "REASON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REASON"])},
      "Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
      "If you click the confirm button, your terracycle account will be deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click the confirm button, your terracycle account will be deleted."])},
      "Are you sure to delete your account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to delete your account?"])},
      "Please add your phone number first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add your phone number first"])}
    }
  })
}
