import { IImage } from './image';
import { ITaxon } from './taxon';

export interface IProduct {
  id: number;
  images: IImage[];
  name: string;
  variants: IVariant[];
  description: string;
  recyclable_image: null | IImage;
  recyclable_list: string[];
  unrecyclable_list: string[];
  important_shipping_restrictions: string;
  work_steps: Array<{ id: number; title: string; description: string; icon: IImage }>;
  taxons: Array<ITaxon>;
}

export interface IVariant {
  id: number;
  /**
   * 价格
   */
  cost_price: string;
  /**
   * 原价
   */
  origin_price: string;
  /**
   * 订阅价
   */
  subscribe_price: string;
  name: string;
  follow: boolean;
  product: IProduct;
  price_type: 'both' | 'one_time_purchase' | 'subscribe' | null;
};

export type TPurchaseKind = 'once' | 'delivery_weekly' | 'delivery_monthly';

export const DEFAULT_PRODUCT: IProduct = {
  id: 0,
  images: [],
  name: '',
  variants: [],
  description: '',
  recyclable_image: null,
  recyclable_list: [],
  unrecyclable_list: [],
  important_shipping_restrictions: '',
  work_steps: [],
  taxons: [],
};

export const DEFAULT_VARIANT: IVariant = {
  id: 0,
  cost_price: '0',
  origin_price: '0',
  subscribe_price: '0',
  name: '',
  follow: false,
  product: DEFAULT_PRODUCT,
  price_type: null
};
