import { createI18n } from 'vue-i18n';
// import { currentI18nLocale } from '@/constants';
const currentI18nLocale = {
  ko: 'ko', ja: 'ja', au: 'en', nz: 'en'
}[process.env.VUE_APP_COUNTRY] || 'en';


const i18n = createI18n({
  locale: currentI18nLocale || 'en',
  fallbackLocale: 'en',
  legacy: false,
  globalInjection: true,
  fallbackRoot: true,
  messages: {
    en: require('@/locales/en').default,
    au: require('@/locales/en').default,
    nz: require('@/locales/en').default,
    ko: require('@/locales/ko').default,
    ja: require('@/locales/ja').default,
  }
});

export default i18n;
