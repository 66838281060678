export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "participate_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여신청"])},
      "dialog_login_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 해주세요"])},
      "dialog_login_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
      "dialog_email_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 인증이 필요합니다."])},
      "dialog_email_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 인증하기"])},
      "dialog_address_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마이페이지에서 주소 및 휴대폰 번호를 확인해주세요"])},
      "dialog_address_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
      "dialog_info_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닫기"])},
      "dialog_participate_success_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로그램에 참여해 주셔서 감사합니다. 픽업 준비가 완료되면 프로그램 페이지에서 픽업 요청 절차를 부탁드립니다."])},
      "apply_for_collection_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["온라인 수거 신청하기"])}
    }
  })
}
