import { SimpleStore } from './helper/simple-store';
import { authStore } from './auth-store';
import { ICollectionBin, getCollectionBinDefaultData } from '@/types/collection-bin';
import { IUser } from '@/types/user';
import { request } from '@/utils';
import { IUserRequest } from '@/types/user-request';

export class ColletionBinStore extends SimpleStore {
  data: ICollectionBin = getCollectionBinDefaultData();
  id: number = 0;
  userRequest: IUserRequest | null = null;
  userInfo: IUser = {};

  get isOfflineCollection() {
    return this.data.target_type === 'OfflineCollection';
  }

  get isPDO() {
    return this.data.target_type === 'PDO';
  }

  async fetch() {
    const { data } = await request.get(`/collection_bins/${this.id}`);
    this.data = data;
    if (this.data.joined_program) {
      this.getUserInfo();
    }
  }

  async getUserInfo() {
    await authStore.tryFetchData();
    const params = {
      user_id: authStore.user.tracking_code,
      collection_bin_id: this.id
    };
    const { data } = await request.get('/user/user_info', { params });
    this.userInfo = data;
  }

  async fetchUserRequest(userRequestId: number) {
    const { data } = await request.get<IUserRequest>(`/user_requests/${userRequestId}`);
    this.userRequest = data;
  }

  async request(barcodes: string[] = []) {
    const { data } = await request.post<IUserRequest>('/user_requests/oc_collection_request', {
      program_id: this.data.program_id,
      collection_bin_id: this.data.id,
      barcodes: barcodes.filter(Boolean),
      is_offline_collection: this.data.target_type === 'OfflineCollection',
    });
    return this.userRequest = data;
  }

  async requestReward(userRequestId: number) {
    const { data } = await request.put<IUserRequest>(`/user_requests/${userRequestId}/oc_reward_apply`);
    return this.userRequest = data;
  }
}
