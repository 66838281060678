export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["運送会社追跡番号"])},
      "Tracking number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TC追跡番号"])},
      "Date created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集荷依頼作成日"])},
      "Date received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録日"])},
      "Recover amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover amount"])},
      "Points earned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得ポイント数"])},
      "Shippment history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送状況"])},
      "Confirm cancellation?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルしますか？"])},
      "Confirm request?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集荷を依頼しますか？"])},
      "No collection history record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集荷履歴がありません"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重さ"])}
    }
  })
}
