export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Offline Collection Records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오프라인 수거 내역"])},
      "Collection Bin Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거함 관리"])},
      "Creat New Submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 수거품목 정보 입력하기"])},
      "Collection method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 방법"])},
      "user_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["셀프 접수"])},
      "store_staff_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매장 직원 접수"])},
      "no_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록 내역 없음"])},
      "make_online_collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["온라인 수거 생성하기"])}
    }
  })
}
