export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
      "Asia Pacific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アジアパシフィック"])}
    }
  })
}
