export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "offline_collection_point_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収拠点"])},
      "tab_online_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人/団体回収"])},
      "tab_offline_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拠点回収"])}
    }
  })
}
