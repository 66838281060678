export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Media Room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メディアルーム"])},
      "desc": [
        (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["テラサイクルオーストラリアのメディアルームへようこそ。ここでは、最新のプレスリリース、画像、一般的な企業情報などをご覧いただけますので、記事やストーリーにご活用ください。メディア関係者の方で、必要なものが見つからない場合、またはインタビューの設定をご希望の場合は、<a href='mailto:", _interpolate(_named("email")), "'>", _interpolate(_named("email")), ".</a> までご連絡ください。"])},
        (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["また、Loop™に関する情報にご興味をお持ちの方は、米国チーム（<a href='mailto:", _interpolate(_named("email")), "'>", _interpolate(_named("email")), "</a>）までご連絡ください。"])},
        
      ]
    }
  })
}
