export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Create Bin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거함 만들기"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOTE"])},
      "note text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성 후에는 이름을 변경할 수 없습니다."])}
    }
  })
}
