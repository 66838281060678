export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경하기"])},
      "Account information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 정보"])},
      "SCREEN NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["페이지 이름"])},
      "FIRST NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
      "LAST NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성"])},
      "NICKNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임"])},
      "ADDRESS 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
      "ADDRESS 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세부 주소"])},
      "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시"])},
      "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도"])},
      "ZIP CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우편번호"])},
      "PHONE NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호"])},
      "nicknameTip": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈페이지 환경 내에서 사용자 이름 또는 닉네임으로 표시됩니다. 자세한 사항은 개인정보처리방침을 참고해주세요."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 보호정책"])},
        
      ],
      "first_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["길동"])},
      "last_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홍"])},
      "nick_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예) 지구지킴이"])},
      "address_1_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도로명주소"])},
      "address_2_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상세 주소"])},
      "state_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구"])},
      "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시"])}
    }
  })
}
