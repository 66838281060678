export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "result_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("keyword")), "\" の ", _interpolate(_named("count")), " 件の結果"])},
      "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ別"])},
      "all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのカテゴリ"])}
    }
  })
}
