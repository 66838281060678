export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The simple, all-in-one solution to recycle hard-to-recycle trash."])},
      "Search story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search story"])}
    }
  })
}
