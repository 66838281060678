export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 거점 등록 신청"])},
      "btn_tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회수 거점으로 등록되면 본 프로그램의 1회 수거점으로 지도에 게재됩니다."])}
    }
  })
}
