export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをお忘れですか?"])},
      "Forgot your ID?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDをお忘れですか？"])}
    }
  })
}
