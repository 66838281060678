export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "result_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("keyword")), "\"에 대한 ", _interpolate(_named("count")), "개의 결과"])},
      "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
      "all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 카테고리"])}
    }
  })
}
