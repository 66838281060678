export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Email information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email information"])},
      "Password information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password information"])},
      "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CURRENT_PASSWORD"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "Verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verified"])},
      "Inconsistent password input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconsistent password input"])},
      "Email not verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not completed the mailbox verification, please complete the verification as soon as possible."])}
    }
  })
}
