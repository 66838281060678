import dayjs from 'dayjs';

export default [
  {
    title: '[이달의 바다]\n열세 번째 바다, 군산 선유도',
    mobileTitle: '',
    desc: [
      '9월의 마지막 주가 되니, 날씨가 꽤 쌀쌀해졌어요. 군산 선유도에는 버려진 어업 도구들이 많이 있었습니다. 폐그물, 폐어망, 부표, 사람의 손길이 오래 닿지 않은 듯한 낡은 선박도 발견했죠. 군산 선유도 활동에는 이마트 임직원분들과 지역 파트너분들이 많이 참여해 주셨습니다. 26명이 모여 총 304.64kg 의 쓰레기를 주웠습니다. '
    ],
    mobileDesc: [],
    time: '2022.09.28',
    image: require('./images/20220928.jpg'),
    popupImage: require('./images/20220928.jpg'),
  },
  {
    title: '해양 쓰레기 중 어업 쓰레기는 얼마나 될까?',
    mobileTitle: '',
    desc: [
      '해양 쓰레기는 크게 나누었을 때 생활 쓰레기와 어구(어업 활동에서 사용하는 도구들)  쓰레기로 나눌 수 있어요. 페트병이나 담배꽁초 등 생활 쓰레기가 큰 비중을 차지할 거라고 생각하시겠지만, 바다에 떠다니는 수많은 쓰레기 중 약 46%는 어구 쓰레기라고 합니다. ',
      '생활 쓰레기보다 무게가 있어  가라앉은 뒤엔 발견하기가 쉽지 않아요. 부피도 훨씬 크기 때문에 바다를 오염시킬 확률이 높죠. 폐어구들을 수거할 수 있는 현실적인 방안이 필요할 것 같습니다.'
    ],
    mobileDesc: [],
    time: '2022.10.04',
    image: require('./images/20221004.jpg'),
    popupImage: require('./images/20221004.jpg'),
  },
  {
    title: '커다란 스티로폼들',
    mobileTitle: '',
    desc: [
      '선유도 연안에는 스티로폼이 온전한 형태로 남아있는 경우가 많았어요. 시간이 오래 지났는지 그 위로 식물 덩굴들이 마구 자라고 있었죠. 그래도 스티로폼이 잘게 부서지기 전에 발견해서 다행입니다. ',
      '다 같이 힘을 모아 커다란 스티로폼들을 옮겼어요.'
    ],
    mobileDesc: [],
    time: '2022.10.11',
    image: require('./images/20221011.jpg'),
    popupImage: require('./images/20221011.jpg'),
  },
  {
    title: '[모두의 바다]\n열네 번째 바다, 부산 감지해변',
    mobileTitle: '',
    desc: [
      '2022년도 이달의 바다 활동이 모두 끝났습니다! 마지막 바다 ‘부산 감지해변’에는 전국 각지에서 파트너사 임직원분들이 와주셨어요. 또, 지난번 부산 활동에 참여해 주셨던 어린이집 선생님들과 시민분들 모두 함께해 주셔서 풍성한 활동이 될 수 있었습니다. 1시간 동안 총 148kg의 쓰레기를 모았습니다. 내년에도 멋진 활동을 이어갈 수 있길 바라요.',
    ],
    mobileDesc: [],
    time: '2022.10.26',
    image: require('./images/20221026.jpg'),
    popupImage: require('./images/20221026.jpg'),
  },
  {
    title: '뒷정리는 깔끔히',
    mobileTitle: '',
    desc: [
      '부산 감지해변은 생활 쓰레기들이 많은 곳이었어요. 낚시를 하러 오거나,  바다를 구경하는 관광객들이 자주 찾는 곳이라고 합니다. 바다를 사랑한다면 오래오래 아름다운 바다를 만날 수 있도록 라면 봉지나 과자 봉지, 낚시 도구들이 들어있던 비닐들은 모두 집으로 가져가요!',
    ],
    mobileDesc: [],
    time: '2022.10.26',
    image: require('./images/20221026-2.jpg'),
    popupImage: require('./images/20221026-2.jpg'),
  },
  {
    title: 'Thanks to,',
    mobileTitle: '',
    desc: [
      '이달의 바다를 빛내준 숨은 주역, 스태프분들 모두 감사드려요! ',
      '전국 각지에서 쓰레기를 주울 수 있는 장소를 찾고, 참여자 분들이 안전하게 활동할 수 있도록 현장을 꾸려 주셨어요. ',
      '또 사진과 동영상 촬영을 통해 심각한 해양 쓰레기 문제를 현장감 있게 잘 담아주셨죠.',
      '일 년간 애써 주셔서 감사드립니다. '
    ],
    mobileDesc: [],
    time: '2022.10.27',
    image: require('./images/20221027.jpg'),
    popupImage: require('./images/20221027.jpg'),
  },
  {
    title: '자갈 속에 묻혀있던 것',
    mobileTitle: '',
    desc: [
      '감지해변에는 담배꽁초나 작은 유리 조각 같은 생활 쓰레기들이 많아서 자갈들 사이를 유심히 살펴보면서 걸었어요. 그러다 엄청나게 크고, 무거운 텐트 천을 발견했죠! ',
      '3명이 힘을 모아 잡아당긴 끝에 겨우 쓰레기를 구출할 수 있었어요.  만약 조금 더 시간이 지났더라면 자갈 속에 묻혀 영영 발견하지 못했을지도 몰라요. ',
    ],
    mobileDesc: [],
    time: '2022.11.05',
    image: require('./images/20221105.jpg'),
    popupImage: require('./images/20221105.jpg'),
  },
  {
    title: '180초 동안 할 수 있는 일',
    mobileTitle: '',
    desc: [
      '성수에서는 10월에도 홀리워터데이가 진행되었습니다! 5명의 인원이 참가한 10월의 활동. ',
      '건설 현장과 주차장 사이를 돌아다니다 보면 어느새 비닐이 가득해질 정도로 담배꽁초가 모여요. 위 사진은 제가 3분 동안 모은 쓰레기의 양입니다. 꽤 많은 양이 모였어요. 용기를 가진다면 하루 10분으로도 충분히 거리를 깨끗하게 할 수 있답니다. 이날은 총 1.1kg의 쓰레기를 수거했어요. ',
    ],
    mobileDesc: [],
    time: '2022.10.31',
    image: require('./images/20221031.jpg'),
    popupImage: require('./images/20221031.jpg'),
  },
  {
    title: '담배꽁초의 습격',
    mobileTitle: '',
    desc: [
      '두 번째 홀리워터 활동 날은 기분 좋은 바람이 부는 날이었어요. 성수에서 가장 흔하게 볼 수 있는 쓰레기는 담배꽁초인데요, 흡연 부스가 따로 설치되어 있지 않다 보니 길거리에, 골목마다 버려진 꽁초들이 무척 많았습니다.',
      '<br>',
      '올여름 쏟아졌던 폭우에는 각종 쓰레기들로 인해 배수로가 막혀 더 큰 피해가 발생하기도 했습니다.  또, 그렇게 빗물과 함께 배수구를 통해 흘러나가는 쓰레기들은 바다를 오염시키는 주요 원인이 되기도 하죠. ',
      '<br>',
      '이날은 총 8명의 팀원이 모여 3.6kg의 쓰레기를 수거했습니다.'
    ],
    mobileDesc: [],
    time: '2022.09.26',
    image: require('./images/image-31.jpg'),
    popupImage: require('./images/image-31.jpg'),
  },
  {
    title: '자유롭게 가져가세요! ',
    mobileTitle: '',
    desc: [
      '환경을 아낄 수 있는 방법은 참 다양해요. 그중에서도 가장 쉬운 일은 이미 만들어진 것을 용도에 맞게 오래오래, 잘 사용하는 일이죠. ',
      '<br>',
      '파트너사인 이마트에서는 판매하기는 어렵지만, 사용하기에 문제가 없는 물티슈를 이달의 바다 참여자분들이 자유롭게 가져갈 수 있도록 전달했어요. ',
      '<br>',
      '상품 가치가 없다는 이유로 버려지던 물건들을 사용하는 일은, 생각보다 환경에 꽤 도움이 됩니다. ',
    ],
    mobileDesc: [],
    time: '2022.09.25',
    image: require('./images/image-29.jpg'),
    popupImage: require('./images/image-29.jpg'),
  },
  {
    title: '스티로폼 알갱이만\n골라낼 수 있는 청소기가 필요해!',
    mobileTitle: '',
    desc: [
      '부산 중리 해변은 시 공공사업을 통해 연안 주변의 길가가 잘 정돈되어 있었습니다. 멀리서 보았을 때는 깨끗해 보였는데 돌을 들추어 보니 역시 이곳도 스티로폼 가루들이 많이 있었어요. 덩어리는 주워내면 그만이지만, 이런 작은 알갱이들은 전부 처리할 방법이 없어 아쉬움이 큽니다. ',
      '이렇게 부스러진 가루들은 금방 바다로 흘러 들어가 미세 플라스틱이 되지요. 스티로폼 알갱이만 쏙 빨아들이는 진공 청소기가 있다면 깨끗한 바다를 만들기 좀 더 수월할텐데. ',
      '보이는 것보다, 잘 보이지 않는 쓰레기가 훨씬 큰 문',
      '제라는 생각이 들었습니다. '
    ],
    mobileDesc: [],
    time: '2022.09.23',
    image: require('./images/image-28.jpg'),
    popupImage: require('./images/image-28.jpg'),
  },
  {
    title: '[이달의 바다]\n열두 번째 바다, 부산 중리',
    mobileTitle: '',
    desc: [
      '부산 바다에 이달의 바다 참여자들이 모였습니다. 아름다운 해 질 녘을 볼 수 있다고 알려진 영도 바다는, 어느덧 가을이 온 듯 날씨가 선선해졌어요. ',
      '<br>',
      '오늘 이달의 바다에는 영도희망21, 이마트 임직원, 자원순환사회연대, 그린웨이브, 유익컴퍼니, 영도구 국공립어린이집지회, 지역주민분들이 함께해 주셨습니다. 총 34명의 참여자가 164,01kg의 해양 쓰레기를 수거했어요. 이달의 바다  활동을 하는 동안 누적된 쓰레기양도 어느덧 5,000kg에 가까워졌습니다.  ',
      '<br>',
      '다음 바다는 어디일까요? ',
    ],
    mobileDesc: [],
    time: '2022.09.22',
    image: require('./images/image-27.jpg'),
    popupImage: require('./images/image-27.jpg'),
  },
  {
    title: '서울-성수에서도 함께 합니다. ',
    mobileTitle: '',
    desc: [
      '얼마 전, 테라사이클 사무실은 성수로 이사를 했어요.',
      '<br>',
      '성수에서 우리가 할 수 있는 환경 보호 활동에는 뭐가 있을지 고민하다가, 소소하게 담배꽁초 줍기 활동을 시작했습니다. 이름은 바로 ‘Holy Water Day : 홀리워터(성수) 데이’ 깨끗한 바다를 지키자는 마음을 담은 언어유희입니다. ',
      '<br>',
      '우선은 한 달에 한 번, 점심시간을 이용해보기로 했어요.  성수동은 유동 인구가 많은 곳이라 담배꽁초 뿐만 아니라 각종 일회용품 쓰레기들을 거리에서 쉽게 찾아볼 수 있었습니다. 바다가 아닌 일상에서도 쓰레기 줍기는 언제든 가능해요!',
      '<br>',
      '성수에서의 첫 번째 홀리워터 활동에는 6명의 팀원이 1.8kg의 쓰레기를 모았습니다.',
    ],
    mobileDesc: [],
    time: '2022.09.02',
    image: require('./images/image-30.jpg'),
    popupImage: require('./images/image-30.jpg'),
  },
  {
    title: '수어 ‘파도’',
    mobileTitle: '',
    desc: [
      '가플지우 참여자들은 쓰레기를 줍기 전 꼭 기념 사진을 찍습니다.  깨끗해질 바다를 생각하며 손으로 파도를 만들어요.',
      '<br>',
      '이 포즈는 수어로 ‘파도’라는 뜻이에요. 큰 바위를 파도가 철~썩 내리치는 모습을 형상화했어요. 의미도 좋고 쉬운 동작이라 기억에 오래 남을 것 같아요.',
      '<br>',
      '깨끗한 우리 바다에 시원한 파도가 칠 수 있도록 함께 해요.',
    ],
    mobileDesc: [],
    time: '2022.08.31',
    image: require('./images/image-26.jpg'),
    popupImage: require('./images/image-26.jpg'),
  },
  {
    title: '가플지우 조끼와 장갑',
    mobileTitle: '',
    desc: [
      '가플지우 ‘이달의 바다’에서 가장  중요한 건 안전과 활동성이겠죠!',
      '장갑은 참여자분들의 손이 다치지 않게 도와줘요. 또 바다에서 활동하는 만큼 물에 닿아도 괜찮은 방수 제품이에요.',
      '<br>',
      '조끼는 주머니가 많고 지퍼도 있어 핸드폰 등 개인 소지품 지참이 간편하고, 바람이 잘 통하도록 통풍 구멍이 있어 시원합니다.  모두 탐내는 인기 아이템이에요.',
    ],
    mobileDesc: [],
    time: '2022.08.27',
    image: require('./images/image-25.jpg'),
    popupImage: require('./images/image-25.jpg'),
  },
  {
    title: '폐어망으로 뒤덮인 돌고래들의 집',
    mobileTitle: '',
    desc: [
      '서귀포시는 제주도에서 유일하게 돌고래들의 서식지가 있는 곳이기도 합니다.',
      '돌고래들의 집이죠.',
      '닭머르에서 만난 남방큰돌고래 친구도 여기에 살고 있을지도 몰라요.',
      '집에 돌아오다가 그물에 걸려 목숨을 잃는 돌고래, 해양 쓰레기를 먹이로 착각하여 죽거나 다치는 돌고래가 꾸준히 늘어나고 있습니다.',
      '깨끗한 바다, 지속 가능한 바다를 위해 우리는 무슨 일을 할 수 있을까요?',
    ],
    mobileDesc: [],
    time: '2022.08.23',
    image: require('./images/image-24.jpg'),
    popupImage: require('./images/image-24.jpg'),
  },
  {
    title: '[이달의 바다]\n열한 번째 바다, ‘서귀포 송악산’',
    mobileTitle: '',
    desc: [
      '서귀포 송악산 인근의 해안가에는 선박에서 버려진 쓰레기들이 아주 많았어요.',
      '부표, 폐어망, 버려진 낚시도구 등 흔적은 사람들이 지나간 자리에 고스란히 남아 있었습니다.',
      '해양 폐기물 중 절반 정도가 어업 과정에서 만들어진 어구 쓰레기입니다.',
      '이틀 동안, 총 55명이 함께 서귀포 송악산 연안에서 228.52kg의 해양쓰레기를 수거했어요.',
    ],
    mobileDesc: [],
    time: '2022.08.21',
    image: require('./images/image-23.jpg'),
    popupImage: require('./images/image-23.jpg'),
  },
  {
    title: '신나는 가플지우 몸풀기 운동',
    mobileTitle: '',
    desc: [
      '가플지우 이달의 바다 활동을 시작하기 전 준비 운동은 필수! 특별한 몸풀기 운동으로 다소 긴장되었던 분위기도 사라지고, 서로 얼굴을 마주 보며 웃기도 해요.',
      '<br>',
      '쓰레기를 줍는 자세를 본뜬 런지 자세도 있고, 깨끗한 바다를 보고 신나게 춤추는 동작도 있어요. 달리는 사진작가 최진성 작가님이 직접 고안해낸 가플지우 체조, 재미있죠?',
      '<br>',
      '몸과 마음이 모두 풀어지면, 이제부터 쓰레기 줍기 시작!',
    ],
    mobileDesc: [],
    time: '2022.08.20',
    image: require('./images/image-22.jpg'),
    popupImage: require('./images/image-22.jpg'),
  },
  {
    title: '[이달의 바다]\n열 번째 바다, ‘닭머르’',
    mobileTitle: '',
    desc: [
      '이름이 참 예쁘고 독특해요. 닭머르.',
      '닭이 흙을 파헤치고 있는 형상이라고 해서 붙여진 이름이라고 해요. 유명하지는 않지만, 걷기 좋은 산책로죠.',
      '또, 아주 가끔이지만 남방큰돌고래 친구들을 볼 수 있는 곳입니다.',
      '날이 좋아서인지, 조금이나마 깨끗한 바다가 기분 좋아서인지 잠깐 모습을 볼 수 있었어요.',
      '닭머르 해안에서 가장 많이 발견했던 쓰레기 종류는 페트병이었어요.',
      '총 44명이 모두 힘을 모아 265.96 kg 의 쓰레기를 모았습니다.',
    ],
    mobileDesc: [],
    time: '2022.08.20',
    image: require('./images/image-21.jpg'),
    popupImage: require('./images/image-21.jpg'),
  },
  {
    title: '바다는 모두 이어져 있어요.',
    mobileTitle: '',
    desc: [
      '낯선 언어가 쓰인 페트병, 표지판. 쓰레기들을 찬찬히 살펴보니 외국어가 적힌 쓰레기들이 꽤 많았어요.',
      '해양 오염을 줄이기 위해서는, 우리나라뿐만 아니라, 모든 국제 사회의 노력이 중요한 이유입니다.',
      '바다는 하나로 이어지고, 어디에 있든 다시 우리에게로 돌아와요.',
    ],
    mobileDesc: [],
    time: '2022.08.19',
    image: require('./images/image-20.jpg'),
    popupImage: require('./images/image-20.jpg'),
  },
  {
    title: '[이달의 바다]\n아홉 번째 바다, ‘제주 애월’',
    mobileTitle: '',
    desc: [
      '8월에는 제주도에서 이달의 바다가 진행되었습니다. 검은 현무암 지대에 버려진 쓰레기들은 평소보다 더 눈에 띄더라구요.',
      '일기예보에서는 비가 올 거라고 했는데 무척 맑은 날씨였습니다. 쓰레기를 줍기 가장 적당한 날이었죠!',
      '<br>',
      '애월 바다에서는 선박에서 버려진 듯한 대형 쓰레기가 많아 여러명이 함께 붙잡고 옮겨야 했어요. 다치지 않도록 장갑은 필수!',
      '이번 이달의 바다 활동에는 제주랩, 이마트 제주점/신제주점 임직원분들, 제주대학교 약학과 재학생분들이 함께했어요.',
      '29명의 참여자가 213.67kg 의 쓰레기를 수거했답니다.',
    ],
    mobileDesc: [],
    time: '2022.08.18',
    image: require('./images/image-19.jpg'),
    popupImage: require('./images/image-19.jpg'),
  },
  {
    title: '업사이클링 ‘쓰레기 썰매’',
    mobileTitle: '',
    desc: [
      '여덟 번째 이달의 바다 양양 죽도 해변에는 특별한 업사이클링 썰매가 등장했어요. 바로바로 폐서프보드를 활용한 쓰레기 썰매!',
      '쓰레기 썰매만 있다면 무거운 쓰레기도 끄떡없지요. 다 채워진 쓰레기봉투를 싣고 안전하게 이동할 수 있답니다.'
    ],
    mobileDesc: [
      '여덟 번째 이달의 바다 양양 죽도 해변에는 특별한 업사이클링 썰매가 등장했어요. 바로바로 폐서프보드를 활용한 쓰레기 썰매!',
      '쓰레기 썰매만 있다면 무거운 쓰레기도 끄떡없지요. 다 채워진 쓰레기봉투를 싣고 안전하게 이동할 수 있답니다.'
    ],
    time: '2022.07.29',
    image: require('./images/image-17.jpg'),
    popupImage: require('./images/image-17.jpg'),
  },
  {
    title: '양양해변에 등장한\n특별한 도구의 정체는?!',
    mobileTitle: '',
    desc: [
      '여덟 번째 이달의 바다 양양 죽도 해변에는 특별한 도구가 등장했어요!',
      '이것의 정체는 무엇일까요?'
    ],
    mobileDesc: [
      '여덟 번째 이달의 바다 양양 죽도 해변에는 특별한 도구가 등장했어요!',
      '이것의 정체는 무엇일까요?'
    ],
    time: '2022.07.29',
    image: require('./images/image-16.jpg'),
    popupImage: require('./images/image-16.jpg'),
  },
  {
    title: '[이달의 바다]\n여덟 번째 바다 ‘양양 죽도 해변’',
    mobileTitle: '',
    desc: [
      '오늘은 여덟 번째 이달의 바다 ‘양양 죽도 해변’에 모였습니다. 양양의 해변은 일명 ‘서핑 성지’로 불리며 여름철이면 수많은 방문객이 몰린답니다. 그래서인지 이번 현장은 지난 바다들과 비교하자면 가장 안전한 환경이기도 했지요. 유익컴퍼니를 비롯해 양양청년협동조합, 쓰담속초, ㈜오션플래닛, 이마트 속초점, 이마트 지속가능혁신센터 ESG추진사무국 등 총 27명이 참여하여 쓰레기 133.16kg을 수거했답니다.'
    ],
    mobileDesc: [
      '오늘은 여덟 번째 이달의 바다 ‘양양 죽도 해변’에 모였습니다. 양양의 해변은 일명 ‘서핑 성지’로 불리며 여름철이면 수많은 방문객이 몰린답니다. 그래서인지 이번 현장은 지난 바다들과 비교하자면 가장 안전한 환경이기도 했지요. 유익컴퍼니를 비롯해 양양청년협동조합, 쓰담속초, ㈜오션플래닛, 이마트 속초점, 이마트 지속가능혁신센터 ESG추진사무국 등 총 27명이 참여하여 쓰레기 133.16kg을 수거했답니다.'
    ],
    time: '2022.07.28',
    image: require('./images/image-15.jpg'),
    popupImage: require('./images/image-15.jpg'),
  },
  {
    title: '깨끗한 바다!\n블랙야크청년셰르파에 맡겨주세요!',
    mobileTitle: '',
    desc: [
      '아름다운 우리 바다를 지키기 위해 이번에는 블랙야크청년셰르파가 인천 덕적도에 모였습니다!',
      '오늘은 35명의 인원이 모여 약 900kg이상의 폐기물을 수거했어요. 이렇게나 많은 쓰레기가 버려져 있다니.... 아름다운 우리 섬이 원래 모습을 되찾을 수 있도록 블래야크청년셰르파는 꾸준히 우리나라 섬을 돌아다니며  비치코밍 활동을 하고 있다고 해요. 궂은 날씨에도 최선을 다 하는 이들, 정말 멋지지 않나요? (박수 짝짝짝!)'
    ],
    mobileDesc: [
      '아름다운 우리 바다를 지키기 위해 이번에는 블랙야크청년셰르파가 인천 덕적도에 모였습니다!',
      '오늘은 35명의 인원이 모여 약 900kg이상의 폐기물을 수거했어요. 이렇게나 많은 쓰레기가 버려져 있다니.... 아름다운 우리 섬이 원래 모습을 되찾을 수 있도록 블래야크청년셰르파는 꾸준히 우리나라 섬을 돌아다니며  비치코밍 활동을 하고 있다고 해요. 궂은 날씨에도 최선을 다 하는 이들, 정말 멋지지 않나요? (박수 짝짝짝!)'
    ],
    time: '2022.07.24',
    image: require('./images/image-18.jpg'),
    popupImage: require('./images/image-18.jpg'),
  },
  {
    title: '반짝반짝 바다유리\n‘씨글라스’',
    mobileTitle: '',
    desc: [
      '꼭 보석처럼 반짝이는 이것의 정체는?! 바로 바다유리 ‘씨글라스(Sea Glass)’랍니다. 바다에 버려진 유리가 시간이 지나 마모되어 이렇게 매끈매끈 보석처럼 변한 것이랍니다. 마치 보석처럼 예뻐서 업사이클링 소재로도 자주 쓰이지요. 그렇지만, 더이상 쓰레기가 바다에 버려지지 않도록 해야겠죠?'
    ],
    mobileDesc: [
      '꼭 보석처럼 반짝이는 이것의 정체는?! 바로 바다유리 ‘씨글라스(Sea Glass)’랍니다. 바다에 버려진 유리가 시간이 지나 마모되어 이렇게 매끈매끈 보석처럼 변한 것이랍니다. 마치 보석처럼 예뻐서 업사이클링 소재로도 자주 쓰이지요. 그렇지만, 더이상 쓰레기가 바다에 버려지지 않도록 해야겠죠?'
    ],
    time: '2022.07.16',
    image: require('./images/image-14.jpg'),
    popupImage: require('./images/image-14.jpg'),
  },
  {
    title: '[이달의 바다]\n일곱 번째 바다 ‘목포 고하도 연안’',
    mobileTitle: '',
    desc: [
      '오늘 가플지우 팀은 목포 고하도 연안에 모였어요. 모처럼 비가 오지 않고 바람이 솔솔 불어와 활동하기 정말 좋은 날씨 였답니다. 오늘 활동에는 이마트 목포점 임직원 분들과, 괜찮아마을, 유달동행정복지센터, 전남 지역주민 등 총 21명이 참여해 주셨어요. 241.54kg의 쓰레기를 모았답니다.'
    ],
    mobileDesc: [
      '오늘 가플지우 팀은 목포 고하도 연안에 모였어요. 모처럼 비가 오지 않고 바람이 솔솔 불어와 활동하기 정말 좋은 날씨 였답니다. 오늘 활동에는 이마트 목포점 임직원 분들과, 괜찮아마을, 유달동행정복지센터, 전남 지역주민 등 총 21명이 참여해 주셨어요. 241.54kg의 쓰레기를 모았답니다.'
    ],
    time: '2022.07.13',
    image: require('./images/image-13.jpg'),
    popupImage: require('./images/image-13.jpg'),
  },
  {
    title: '가플지우 쓰레기 집게의 정체는?!',
    desc: [
      '이달의 바다 활동에 빠질 수 없는 필수템 가플지우 집게!',
      '<br>',
      '가플지우 집게가 여러분이 그동안 이마트에 모아 주신 폐플라스틱을 재활용해 만들어졌다는 사실, 알고 계셨나요?',
      '<br>',
      '여러분이 이마트로 가져와 주신 재활용이 어려운 폐플라스틱 용기는 테라사이클을 통해 모두 재활용 되어 다시 자원으로 쓰이게 된답니다. 이제는 온라인으로도 재활용 프로그램에 쉽게 참여할 수 있어요.',
      '<br>',
      '여러분의 많은 관심과 참여 부탁드립니다!'
    ],
    time: '2022.07.07',
    image: require('./images/image-1.jpg'),
  },
  {
    title: '포스코 클린오션봉사단을 소개합니다.',
    desc: [
      '포스코 광양제철소 클린오션봉사단은 스킨스쿠버 자격증을 갖춘 직원들이 뜻을 모아 2009년 창단한 재능봉사단입니다.',
      '<br>',
      '이렇게 개인이 가진 재능을 활용해 매월 2회 정기적으로 광양제철소 인근 지역 연안에서 해양환경 보전 활동에 앞장서고 있는데요.',
      '<br>',
      '연안 쓰레기 뿐만 아니라, 스킨스쿠버 장비를 착용하고 직접 바다로 들어가 폐그물과 같은 해양 폐기물을 수거해 수중 정화활동도 하고 있어요.',
      '깨끗한 바다를 지키기 위해 노력하는 클린오션봉사단을 응원해주세요!'
    ],
    time: '2022.07.05',
    image: require('./images/image-2.jpg'),
  },
  {
    title: '[이달의 바다]\n여섯 번째 바다 ‘광양 배알도’',
    desc: [
      '오늘 광양 배알도 수변공원 연안에는 그 어느때보다 많은 분들이 찾아주셨어요.',
      '<br>',
      '유익컴퍼니를 필두로 이마트 지속가능혁신센터 ESG추진사무국, 포스코 마케팅전략실 임직원, 광양제철소 클린오션봉사단 및 해양정화봉사단, 이마트 여수점, 환경부 탄소중립 청년 서포터즈, 광양매화원, (주)함께에듀, 광양어민회, 전남 지역 주민 등 총 117명이나 이달의 바다 활동에 참여해 이날 하루만 1톤이 넘는 쓰레기를 수거했답니다. (박수 짝짝짝!)'
    ],
    time: '2022.07.02',
    image: require('./images/image-3.jpg'),
  },
  {
    title: '[이달의 바다]\n활동 키트 소개',
    desc: [
      '이달의 바다 활동에 참여한 분들에게만 드리는 특별한 선물이 있는데요.',
      '바로바로 이달의 바다 활동 키트입니다!',
      '<br>',
      '소지품과 옷가지를 담을 수 있는 타이벡 소재의 가방안에는 흐르는 땀을 닦을 수 있는 가플지우 손수건, 안전을 위한 목장갑, 이달의 바다 활동 가이드북과 간식이 들어 있어요.',
      '<br>',
      '일상으로 돌아가서도 가플지우 가방은 일회용 플라스틱 비닐봉투 대신 사용하고, 손수건은 휴지 대신 활용해주세요. 우리의 작은 실천이 내일의 지구를 만들 수 있어요.'
    ],
    time: '2022.06.30',
    image: require('./images/image-4.jpg'),
  },
  {
    title: '[이달의 바다]\n다섯 번째 바다 ‘목포 고하도 윗마을’',
    desc: [
      '오늘은 유익컴퍼니, 유한회사 비파밍, 이마트 목포점, 전남 지역 주민 22명이 목포 고하도 윗마을 해변에 모였습니다!',
      '버려지고 쌓인 쓰레기가 마치 땅처럼 굳어버려 유난히 힘들었던 다섯 번째 바다.',
      '<br>',
      '모두 힘을 합쳐 총 215.78kg의 쓰레기를 수거할 수 있었답니다.',
      '우리의 작은 실천이 조금이나마 깨끗한 바다를 지키는 일에 도움이 되길 바라며 다음 이달의 바다에서 또 만나요!'
    ],
    time: '2022.06.23',
    image: require('./images/image-5.jpg'),
  },
  {
    title: '[이달의 바다]\n네 번째 바다 ‘여수 큰끝등대’',
    desc: [
      '구름 한 점 없이 화창했던 6월 16일, 유익컴퍼니를 필두로 아쿠아플라넷 여수, 이마트 지속가능혁신센터 ESG추진사무국, 이마트 여수점, 한국부인회 여수시지회, 해양환경인명구조단 여수지구대, 지역 주민 등 총 45명이 모여 687kg의 쓰레기를 주웠습니다.',
      '<br>',
      '뙤약볕에 조금만 걸어도 땀이 흐르는 날씨였지만, 우리의 실천이 내일의 깨끗한 바다를 만들 수 있다는 마음에 힘들지 않았답니다.'
    ],
    time: '2022.06.16',
    image: require('./images/image-6.jpg'),
  },
  {
    title: '[이달의 바다]\n한국 P&G가 함께한 부산 바다',
    desc: [
      '깨끗한 바다를 지키기 위해 한국 P&G, 해양환경공단 임직원이 부산에 모였습니다.',
      '<br>',
      '지난 5월 26일 제주도에서의 연안정화활동에 이어 두 번째 모임인데요. 오늘은 약 40여명의 임직원이 뜻을 모아 부산 바다 일대의 쓰레기 약 21kg을 주웠어요.',
      '<br>',
      '전국 각지에서 활약하는 가플지우 파트너사들! 다음 활동도 기대해주세요!'
    ],
    time: '2022.06.10',
    image: require('./images/image-7.jpg'),
  },
  {
    title: '[이달의 바다]\n세 번째 바다 ‘여수 금봉리’',
    desc: [
      '비가 추적추적 내리는 궂은 날씨에도 불구하고 해양환경인명구조단 여수지구대와 유익컴퍼니 등 총 29명이 모여 세 번째 연안정화활동을 이어 나갔습니다.',
      '<br>',
      '오늘은 커다란 타이어와 각종 플라스틱 부표 쓰레기가 많아 무려 400kg 넘는 쓰레기가 수거됐답니다.',
      '오늘의 실천이 내일의 깨끗한 바다를  만들 수 있도록 가플지우 캠페인에 함께해 주세요.'
    ],
    time: '2022.06.05',
    image: require('./images/image-8.jpg'),
  },
  {
    title: '[이달의 바다]\n두 번째 바다 ‘순천 노월’',
    desc: [
      '이달의 바다 두 번째 활동은 순천 노월 전망대 앞에서 진행됐습니다.',
      '<br>',
      '이번 활동에도 역시 이마트 순천점 임직원을 비롯해 지역주민들이 모여 총 49명이 함께 101.62kg의 쓰레기를 주웠습니다.',
      '<br>',
      '비 소식이 있어서 걱정이 많았지만, 다행히도 화창한 날씨로 모두 안전하게 활동을 마무리할 수 있었답니다.',
      '다음 이달의 바다는 어디일까요? 기대해 주세요.'
    ],
    time: '2022.05.29',
    image: require('./images/image-9.jpg'),
  },
  {
    title: '[이달의 바다]\n첫 번째 바다 ‘순천 와온’',
    desc: [
      '이달의 바다 첫 번째 활동은 전남 순천에서 시작됐습니다.',
      '<br>',
      '이마트 순천점 임직원, 유익컴퍼니, 전남동부지역사회연구소, 지역 주민 등 총 24명이 순천 와온길에 모여 154.14kg의 쓰레기를 주웠습니다.',
      '<br>',
      '일몰 명소로 유명한 순천 와온 해변의 아름다움을 지켜갈 수 있도록, 가플지우 캠페인을 응원해 주세요.'
    ],
    time: '2022.05.20',
    image: require('./images/image-10.jpg'),
  },
  {
    title: '포스코 클린오션봉사단을 소개합니다.',
    mobileTitle: '포스코 탄방소년단을\n소개합니다!',
    desc: [
      '2022년 4월 28일 지구의 날을 기념해 포스코',
      '임직원이 모여 서울 탄천을 지키는 ‘탄방소년단’을',
      '결성했습니다.'
    ],
    mobileDesc: [
      '2022년 4월 28일 지구의 날을 기념해 포스코 임',
      '직원이 모여 서울 탄천을 지키는 ‘탄방소년단’을',
      '결성했습니다.'
    ],
    time: '2022.04.28',
    image: require('./images/image-11.jpg'),
    popupImage: require('./images/popup-image-11.jpg'),
  },
  {
    title: '가플지우 파트너십 체결',
    mobileTitle: '',
    desc: [
      '2022년 4월 14일 13개 파트너사가 모여',
      '가플지우 캠페인 파트너십을 체결했습니다.'
    ],
    mobileDesc: [
      '지난 4월 14일 13개 파트너사가 모여',
      '가플지우 캠페인 파트너십을 체결했습니다.'
    ],
    time: '2022.04.14',
    image: require('./images/image-12.jpg'),
    popupImage: require('./images/popup-image-12.jpg'),
  }
].sort((a, b) => dayjs(b.time).diff(dayjs(a.time)));
