export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료 되었습니다! 오프라인 수거에 참여해주셔서 감사합니다"])},
      "success_reward_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료 되었습니다! 오프라인 수거에 참여해주셔서 감사합니다. 대기 중인 리워드 수령이 가능합니다"])},
      "can_submit_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["오늘 하루 동안 ", _interpolate(_named("num")), "개의 수거 품목 접수를 진행 할 수 있습니다"])},
      "can_submit_for_campaign": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이 캠페인에서 총 ", _interpolate(_named("num")), "개의 수거물품을 접수할 수 있습니다"])},
      "well_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감사합니다! 최대 수거 품목 접수량을 달성하셨습니다. 캠페인에 참여해주셔서 감사합니다."])},
      "well_done_not_limited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여해주셔서 감사합니다. "])},
      "oc_tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["획득한 쿠폰 또는 포인트는 “마이페이지”에서 확인할 수 있습니다."])}
    }
  })
}
