import { BaseType, getBaseData } from './base';

export interface ICompanyAccount extends BaseType {
  is_block: boolean;
  name: string;
  role: 'store_sub';
  store_address?: {
    street_address: string;
    region: string;
    locality: string;
    postal_code: string;
    phone_number: string;
    extended_address: string;
    geographic_coordinates: string;
  }
}

export function getDefaultCompanyAccount(): ICompanyAccount {
  return {
    ...getBaseData(),
    is_block: false,
    name: '',
    role: 'store_sub',
    store_address: {
      street_address: '',
      region: '',
      locality: '',
      postal_code: '',
      phone_number: '',
      extended_address: '',
      geographic_coordinates: ''
    }
  };
};
