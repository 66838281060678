export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "New Charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい寄付先 (チャリティ)"])},
      "TerraCycle Recycling Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テラサイクル リワードアイテム"])},
      "Please fill in all required fields below.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必要な項目を入力してください。ただしご不明な場合は「不明」と記入。"])},
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄付先の名前"])},
      "Organization Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["団体タイプ"])},
      "Address 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
      "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市町区村"])},
      "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都道府県"])},
      "Zip code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
      "Contact Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["担当者名"])},
      "Contact Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先"])},
      "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
      "Federal Tax ID Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法人番号"])}
    }
  })
}
