export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この記事は役に立ちましたか?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いいえ"])},
      "found_this_helpful": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " 人中 ", _interpolate(_named("helpCount")), " 人がこれは役に立ったと評価"])}
    }
  })
}
