export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 신청"])},
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])}
    }
  })
}
