export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Redeem my points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイントを使用する"])},
      "Redeem history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用履歴"])},
      "Your current have:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在お持ちのポイント数:"])},
      "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイント"])},
      "Favorite charities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お気に入りの寄付先"])},
      "texts": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お気に入りの登録がありません "])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全ての寄付先を見る"])},
        
      ]
    }
  })
}
