export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "banner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無料のリサイクルプログラム"])},
      "banner_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リサイクルが難しいとされてきたモノをリサイクルできる無料のプログラムの一覧です。プログラムへの参加を通して、地球への環境負荷の軽減の一端を担いませんか。"])}
    }
  })
}
