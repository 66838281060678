export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Delete Terracycle Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["탈퇴하기"])},
      "REASON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["탈퇴 사유"])},
      "Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["컨텐츠"])},
      "If you click the confirm button, your terracycle account will be deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인을 누르면 테라사이클 계정이 삭제됩니다."])},
      "Are you sure to delete your account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정을 삭제 하시겠습니까??"])},
      "Please add your phone number first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호를 입력하세요"])}
    }
  })
}
