export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有"])},
      "viewsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "ビュー"])},
      "writing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["書き込み"])},
      "shareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テラサイクルの物語\n共有する"])},
      "copySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピー成功"])}
    }
  })
}
