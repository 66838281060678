export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운송장 번호"])},
      "Tracking number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접수 번호"])},
      "Date created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청일"])},
      "Date received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입고일"])},
      "Recover amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재활용 무게"])},
      "Points earned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적립된 포인트"])},
      "Shippment history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거내역"])},
      "Confirm cancellation?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소 하시겠습니까?"])},
      "Confirm request?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이대로 요청하시겠습니까??"])},
      "No collection history record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 신청 내역이 없습니다"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무게"])}
    }
  })
}
