export interface EMartShopListType {
  name: string;
  address: string;
}

export const EMartShopList: EMartShopListType[] = [
  {
    name: '가든5점',
    address: '서울특별시 송파구 충민로 10 (문정동 628 번지)',
  },
  {
    name: '안양점',
    address: '경기고 안양시 관악대로 104',
  },
  {
    name: '양재점',
    address: '서울특별시 서초구 매헌로 16',
  },
  {
    name: '검단점',
    address: '인천광역시 서구 서곶로 754',
  },
  {
    name: '양주점',
    address: '경기도 양주시 평화로 1713',
  },
  {
    name: '경기광주점',
    address: '경기 광주시 광주대로 30',
  },
  {
    name: '여의도점',
    address: '서울특별시 영등포구 여의동로3길 10',
  },
  {
    name: '계양점',
    address: '인천광역시 계양구 봉오대로 785',
  },
  {
    name: '여주점',
    address: '경기도 여주시 세종로 151',
  },
  {
    name: '고잔점',
    address: '경기도 안산시 단원구 원포공원1로 46',
  },
  {
    name: '역삼점',
    address: '서울특별시 강남구 역삼로 310',
  },
  {
    name: '과천점',
    address: '경기 과천시 별양상가3로 11',
  },
  {
    name: '연수점',
    address: '인천광역시 연수구 경원대로 184',
  },
  {
    name: '광교점',
    address: '수원시 영통구 이의동 1240',
  },
  {
    name: '영등포점',
    address: '서울특별시 영등포구 영중로 15',
  },
  {
    name: '광명소하점',
    address: '경기도 광명시 소하로 97',
  },
  {
    name: '오산점',
    address: '경기도 오산시 경기대로 181',
  },
  {
    name: '광명점',
    address: '경기도 광명시 오리로 97',
  },
  {
    name: '왕십리점',
    address: '서울특별시 성동구 왕십리광장로 17',
  },
  {
    name: '구로점',
    address: '서울 구로구 디지털로32길 43',
  },
  {
    name: '용인점',
    address: '경기도 용인시 처인구 명지로 53',
  },
  {
    name: '김포한강점',
    address: '경기도 김포시 구래동 5420-1',
  },
  {
    name: '월계점',
    address: '서울특별시 노원구 마들로3길 15',
  },
  {
    name: '남양주점',
    address: '경기도 남양주시 늘을2로 27',
  },
  {
    name: '은평점',
    address: '서울 은평구 은평로 111',
  },
  // {
  //   name: '동백점',
  //   address: '경기도 용인시 기흥구 동백죽전대로 444',
  // },
  {
    name: '의왕점',
    address: '경기 의왕시 경수대로 262',
  },
  {
    name: '동인천점',
    address: '인천 중구 인중로 134',
  },
  {
    name: '의정부점',
    address: '경기도 의정부시 민락로 210',
  },
  {
    name: '동탄점',
    address: '경기도 화성시 동탄중앙로 376',
  },
  {
    name: '이수점',
    address: '서울특별시 동작구 사당로 300',
  },
  {
    name: '마포공덕점',
    address: '서울 마포구 백범로 212',
  },
  {
    name: '이천점',
    address: '경기 이천시 이섭대천로 1440-50',
  },
  // {
  //   name: '명일점',
  //   address: '서울특별시 강동구 고덕로 276',
  // },
  {
    name: '일산점',
    address: '경기 고양 일산동구 중앙로 1124 (백석동)',
  },
  {
    name: '목동점',
    address: '서울특별시 양천구 오목로 299',
  },
  {
    name: '자양점',
    address: '서울특별시 광진구 아차산로 272',
  },
  {
    name: '묵동점',
    address: '서울특별시 중랑구 동일로 932',
  },
  {
    name: '죽전점',
    address: '경기도 용인시 수지구 포은대로 552',
  },
  {
    name: '미아점',
    address: '서울특별시 성북구 도봉로 17',
  },
  {
    name: '중동점',
    address: '경기도 부천시 원미구 석천로 188',
  },
  {
    name: '별내점',
    address: '경기도 남양주시 순화궁로 167',
  },
  {
    name: '진접점',
    address: '경기도 남양주시 진접읍 금강로유연들1길 154',
  },
  {
    name: '보라점',
    address: '경기도 용인시 기흥구 한보라1로 92',
  },
  {
    name: '창동점',
    address: '서울시 도봉구 노해로 65길 4',
  },
  {
    name: '부천점',
    address: '경기도 부천시 소사구 부천로 1',
  },
  {
    name: '천호점',
    address: '서울 강동구 천호대로 1017',
  },
  {
    name: '분당점',
    address: '경기 성남 분당구 불정로 134',
  },
  {
    name: '청계천점',
    address: '서울특별시 중구 청계천로 400',
  },
  {
    name: '산본점',
    address: '경기 군포시 산본로 347',
  },
  {
    name: '킨텍스점',
    address: '경기도 고양시 일산서구 킨텍스로 171',
  },
  {
    name: '상봉점',
    address: '서울 중랑구 상봉로 118',
  },
  {
    name: '파주운정점',
    address: '경기도 파주시 한울로 123 (동패동 파주운정지구 F2-5블럭)',
  },
  {
    name: '서수원점',
    address: '경기도 수원시 권선구 수인로 291',
  },
  {
    name: '파주점',
    address: '경기도 파주시 당하길 10',
  },
  {
    name: '성남점',
    address: '경기도 성남시 수정구 수정로 201',
  },
  {
    name: '평촌점',
    address: '경기도 안양시 동안구 시민대로 300',
  },
  {
    name: '성수점',
    address: '서울 성동구 뚝섬로 379',
  },
  {
    name: '평택점',
    address: '경기 평택시 영신로 29',
  },
  {
    name: '수색점',
    address: '서울특별시 은평구 수색로 217',
  },
  {
    name: '포천점',
    address: '경기도 포천시 호국로 915',
  },
  {
    name: '수서점',
    address: '서울특별시 강남구 광평로 280',
  },
  {
    name: '풍산점',
    address: '경기도 고양시 일산동구 무공화로 237 (중산동)',
  },
  {
    name: '수원점',
    address: '경기 수원시 권선구 경수대로 270',
  },
  {
    name: '하남점',
    address: '경기도 하남시 덕풍서로 70',
  },
  {
    name: '수지점',
    address: '경기도 용인시 수지구 수지로 203',
  },
  {
    name: '하월곡점',
    address: '서울특별시 성북구 종암로 167',
  },
  {
    name: '시화점',
    address: '경기도 시흥시 정왕대로 210',
  },
  {
    name: '화성봉담점',
    address: '경기도 화성시 봉담읍 효행로 278(외25필지)',
  },
  {
    name: '신도림점',
    address: '서울특별시 구로구 새말로 97',
  },
  {
    name: '화정점',
    address: '경기도 고양시 덕양구 백양로 79',
  },
  {
    name: '신월점',
    address: '서울특별시 양천구 화곡로 59',
  },
  {
    name: '흥덕점',
    address: '경기도 용인시 기흥구 흥덕중앙로 60',
  },
  {
    name: '안성점',
    address: '경기도 안성시 중앙로 246',
  }
];
