import urlJoin from 'url-join';
import { CAPTCHA_RCODE_SESSION_KEY } from '@/constants';

const { VUE_APP_WEB_API, VUE_APP_ENV } = process.env;

function getRandom() {
  return 'tc' + Date.now().toString(36).slice(4) + Math.random().toString(36).replace('0.', '');
}

export function getCaptchaSrc() {
  const random = getRandom();
  const url = urlJoin('/app_api/v1/captcha', `?rcode=${random}`);
  window[CAPTCHA_RCODE_SESSION_KEY] = random;
  return VUE_APP_ENV === 'development' ? url : urlJoin(VUE_APP_WEB_API, url);
}
