export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Sign Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
      "Sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
      "Search programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search programs"])}
    }
  })
}
