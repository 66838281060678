export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更する"])},
      "Account information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント情報"])},
      "SCREEN NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCREEN NAME"])},
      "FIRST NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名（＊全角で入力してください）"])},
      "LAST NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓（＊全角で入力してください）"])},
      "NICKNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー名 (全角で入力してください）"])},
      "ADDRESS 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市区町村以降（＊全角で入力してください）"])},
      "ADDRESS 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マンション・建物名（＊全角で入力してください）"])},
      "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市区町村 （＊全角で入力してください）"])},
      "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都道府県 （＊全角で入力してください）"])},
      "ZIP CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号（ハイフンなし、半角英数字で入力してください）"])},
      "PHONE NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号（ハイフンなし、半角英数字で入力してください）"])},
      "nicknameTip": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネームはコンテスト参加時に表示されます。 弊社プライバシーポリシーをご確認ください。"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシーポリシー"])},
        
      ],
      "first_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例)太郎"])},
      "last_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例)田中"])},
      "nick_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例）タロウ"])},
      "address_1_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例) ｘｘ１−１−１"])},
      "address_2_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例）ｘｘビル１０１"])},
      "state_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択してください"])},
      "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ｘｘ区"])}
    }
  })
}
