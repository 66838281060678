export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("program")), " の回収拠点になる"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収拠点として登録されると本プログラムの1回収拠点として地図に掲載されます"])},
      "facility_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施設名"])},
      "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
      "address_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所1"])},
      "address_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドレス2"])},
      "opening_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["営業時間"])},
      "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正しい情報を入力してください。リクエストが承認されると、回収拠点の情報が地図に表示されます。"])},
      "show_phone_on_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地図上に電話番号を表示するかどうか"])},
      "phone_number_tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号の掲載を希望される場合のみご入力ください。"])}
    }
  })
}
