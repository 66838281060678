export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Offline Collection Records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline Collection Records"])},
      "Collection Bin Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection Bin Management"])},
      "Creat New Submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creat New Submission"])},
      "Collection method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection method"])},
      "user_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User registration"])},
      "store_staff_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store staff registration"])},
      "no_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No registration"])},
      "make_online_collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make online collection"])}
    }
  })
}
