import { SimpleStore } from './helper/simple-store';
import { BreadcrumbRoute } from '@/types/breadcrumb-route';

class MineBreadcurmbStore extends SimpleStore {
  data: BreadcrumbRoute[] = [];

  update(list: BreadcrumbRoute[]) {
    this.data = list;
  }

  clear() {
    this.data = [];
  }
}

export const mineBreadcurmbStore = MineBreadcurmbStore.create<MineBreadcurmbStore>();
