export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Email information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメール情報"])},
      "Password information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード情報"])},
      "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のパスワード"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存する"])},
      "Verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードを送信する"])},
      "Inconsistent password input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードが一致しません"])},
      "Email not verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスの認証が完了していません。メールをご確認ください。"])}
    }
  })
}
