export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Media Room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Room"])},
      "desc": [
        (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome to TerraCycle Australia's media room. Here you'll find the latest press releases, images and general corporate information available for use in your story or article. If you’re a member of the media and don’t see what you need or want to set up an interview, please contact <a href='mailto:", _interpolate(_named("email")), "'>", _interpolate(_named("email")), ".</a>"])},
        (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alternatively, if you are interested in information about Loop™, please contact our team in the US at <a href='mailto:", _interpolate(_named("email")), "'>", _interpolate(_named("email")), ".</a>"])},
        
      ]
    }
  })
}
