export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Verification code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コード"])},
      "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信する"])},
      "CAPTCHA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAPTCHA (右に表示された文字を入力してください）"])},
      "Captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["右に表示された文字を入力してください"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
      "Phone is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必須項目です"])},
      "Email address is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必須項目です"])},
      "Send verification code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードを上記アドレスに送信する"])},
      "Captcha is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captchaの入力は必須です"])},
      "sended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信完了"])}
    }
  })
}
