import { BaseType, getBaseData } from './base';
import { IImage } from './image';
import { IProgram } from './program';
import { IOfflineCollection, getOfflineCollectionDefaultData } from './offline-collection';
import { ICompanyAccount, getDefaultCompanyAccount } from './company-account';
import { PDOType } from './pdo';

export interface ICollectionBin extends BaseType {
  can_request_collection: boolean;
  request_collection_msg: string;
  name: string;
  address: string;
  program_id: number;
  target_id: number;
  qrcode?: IImage;
  program?: IProgram;
  target?: IOfflineCollection | PDOType;
  target_type: 'OfflineCollection' | 'PDO';
  company_account: ICompanyAccount;
  joined_program: boolean
}

export function getCollectionBinDefaultData(): ICollectionBin {
  return {
    ...getBaseData(),
    can_request_collection: false,
    request_collection_msg: '',
    name: '',
    address: '',
    program_id: 0,
    target_id: 0,
    target: getOfflineCollectionDefaultData(),
    target_type: 'OfflineCollection',
    company_account: getDefaultCompanyAccount(),
    joined_program: false
  };
}
