import { SimpleStore } from './helper/simple-store';
import { IFaqSection } from '@/types/faq-section';
import { request } from '@/utils';

class FaqSectionsStore extends SimpleStore {
  data: IFaqSection[] = [];
  params = {
    per_page: 20
  }

  async fetch() {
    const { data } = await request.get<IFaqSection[]>('/faq_sections', { params: { page: 0 } });
    this.data = data;
  }

  findItemById(id: number | string) {
    return this.data.find(item => String(item.id) === String(id));
  }
}

export const faqSectionsStore = FaqSectionsStore.create<FaqSectionsStore>();
