export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Reset password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재설정"])},
      "PHONE NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호"])},
      "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
      "CAPTCHA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보안인증"])}
    }
  })
}
