export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "btn_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
      "Cancel the collection request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel the collection request"])},
      "Apply for participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply for participate"])},
      "Apply for collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply for collection"])},
      "ready_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you ready to be ", _interpolate(_named("name")), " ?"])},
      "ready_dialog_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solve the Quiz!"])},
      "confirm_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
      "participated_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’ve already participated"])},
      "cancel_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel your application?"])},
      "cancel_dialog_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click the Close button, the campaign application will be cancelled."])},
      "one_more_time_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONE MORE TIME !"])},
      "one_more_time_dialog_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If selected as a participants all contact and shipping addresses will be forwarded to the representative who has applied."])},
      "completed_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAMPAIGN APPLICATION COMPLETED"])},
      "completed_dialog_content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome, ", _interpolate(_named("name")), "! If selected as a campaign, participant zero waste box will be sent to the address below. If you want to change the receiving address Checking Application Details & Changing Address please click the button."])},
      "completed_dialog_btn_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check application details & Change address"])},
      "completed_dialog_btn_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share to KakaoTalk Friends"])},
      "apply_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["THANKS, ", _interpolate(_named("name")), "!"])},
      "participate_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " How will you participate?"])},
      "participate_together": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " TOGETHER!"])},
      "participate_alone": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " ALONE!"])},
      "apply_success_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application for collection has been completed. The driver of Hanjin Courier will visit you within 2-3 days at the address you entered when registering as a member."])},
      "button_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, please"])},
      "butotn_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, thanks"])},
      "verify_email_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email adddress needs to be verified."])},
      "verify_email_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Email"])},
      "address_check_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It looks like you don't have an address yet. First add your address."])},
      "address_check_dialog_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill Address"])}
    }
  })
}
