export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗スタッフに会員コードを提示してください。"])},
      "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗スタッフに回収物のバーコードを提示してください。"])},
      "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収物を回収ボックスに投函してください。"])}
    }
  })
}
