export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "remaining_number_tips": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["あと", _interpolate(_named("num")), "個登録可能です。"])},
      "participate_join_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このユーザーはリサイクル プロジェクトに参加していません。参加させますか？"])},
      "participate_join_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無事に参加"])}
    }
  })
}
