export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매장 직원이 회원 QR코드를 스캔합니다"])},
      "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매장 직원이 수거 품목의 제품 바코드를 스캔합니다"])},
      "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보 업로드 후, 수거 품목을 수거함에 넣을 수 있습니다"])}
    }
  })
}
