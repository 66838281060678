import { createApp } from 'vue';
import _, { LoDashStatic } from 'lodash';
import App from './app.vue';
import router from './router';
import i18n from './i18n';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { isMobile } from '@/utils';

dayjs.extend(localizedFormat);

if (process.env.VUE_APP_ENV !== 'production' && isMobile()) {
  const VConsole = require('vconsole');
  new VConsole();
}

// eslint-disable-next-line import/no-named-as-default
import BootstrapVue3, { BToastPlugin } from 'bootstrap-vue-3';
import component from '@/components';
import templateComponent from '@/templates';


import 'animate.css';

import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-vue-3/src/styles/styles.scss';
import '@/styles/global.scss';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $get: LoDashStatic['get']
  }
}

const app = createApp(App);
app.config.globalProperties.$get = _.get;

app.config.compilerOptions.isCustomElement = (tag) => {
  return tag === 'df-messenger';
};

if (process.env.VUE_APP_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://37773e93a21843c880699369140dce71@sentry.beansmile-dev.com/111',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /^\//],
      }),
    ],
    environment: process.env.VUE_APP_ENV,
    release: process.env.VUE_APP_ENV,
    tracesSampleRate: 1.0,
  });
}
app.use(i18n);
app.use(component);
app.use(templateComponent);
app.use(BootstrapVue3);
app.use(BToastPlugin);
app.use(router).mount('#app');

