export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "PHONE NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호"])},
      "CAPTCHA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보안인증"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 찾기"])},
      "my_id_is": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["아이디 :", _interpolate(_named("id"))])}
    }
  })
}
