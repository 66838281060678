export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Collection Bin Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection Bin Management"])},
      "Create Bin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Bin"])},
      "Created at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
      "Bin Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bin Name"])}
    }
  })
}
