export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "New Charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 기부"])},
      "TerraCycle Recycling Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테라사이클 포인트 제도"])},
      "Please fill in all required fields below.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["빈칸에 들어갈 내용을 기재해주세요"])},
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
      "Organization Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단체 유형"])},
      "Address 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
      "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시"])},
      "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도"])},
      "Zip code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우편번호"])},
      "Contact Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표자명"])},
      "Contact Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 번호"])},
      "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메일 주소"])},
      "Federal Tax ID Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주민등록번호"])}
    }
  })
}
