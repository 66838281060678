export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Sign in via Terracycle website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テラサイクルのウェブサイト経由でサインインする"])},
      "or Sign in via SNS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もしくは、SNSでサインインする"])},
      "Sign in via SNS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNSでサインインする"])},
      "Don't have an account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントをお持ちではありませんか？"])}
    }
  })
}
