export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Reset password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをリセットする"])},
      "PHONE NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
      "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
      "CAPTCHA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAPTCHA"])}
    }
  })
}
