export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "step": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ステップ ", _interpolate(_named("index"))])},
      "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収品のバーコードをスキャン"])},
      "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収物を回収ボックスに投函後、「回収物を登録する」ボタンをクリックしてください。"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収物を登録する"])},
      "add_more_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["さらにアイテムを追加"])},
      "collection_bin_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<span class=\"strong\">", _interpolate(_named("name")), "</span> を集めています"])},
      "remaining_number_tips": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["あと", _interpolate(_named("num")), "個登録可能です。"])},
      "scan_tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["をクリックして回収物に記載されたバーコードをスキャン。カメラが機能しない場合は、バーコード下に記載された数字を入力してください。"])}
    }
  })
}
