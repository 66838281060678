import lodash from 'lodash';

const IMAGE_BASE_URL = 'https://tc-mini-site-kr-prod-frontend.s3.amazonaws.com/campaign/cocacola-s5';

const imagesDef: Record<string, { image: string, webp?: string }> = {
  'bottle-45deg': {
    'image': 'bottle-45deg.png',
    'webp': 'bottle-45deg.webp'
  },
  'bottle-135deg-1-line': {
    'image': 'bottle-135deg-1-line.png',
    'webp': 'bottle-135deg-1-line.webp'
  },
  'bottle-135deg-3-line': {
    'image': 'bottle-135deg-3-line.png',
    'webp': 'bottle-135deg-3-line.webp'
  },
  'mission-desc-pc': {
    'image': 'mission-desc-pc.png',
    'webp': 'mission-desc-pc.webp'
  },
  'mission-desc': {
    'image': 'mission-desc.png',
    'webp': 'mission-desc.webp'
  },
  'mission-bottle-box': {
    'image': 'mission-bottle-box-v3.gif',
  },
  'bg-red': {
    'image': 'bg-red.jpeg',
    'webp': 'bg-red.webp'
  },
  'main-bottle-1': {
    'image': 'main-bottle-1.png',
    'webp': 'main-bottle-1.webp'
  },
  'main-bottle-2': {
    'image': 'main-bottle-2.png',
    'webp': 'main-bottle-2.webp'
  },
  'main-bottle-3': {
    'image': 'main-bottle-3.png',
    'webp': 'main-bottle-3.webp'
  },
  'main-img': {
    'image': 'main-img-v3.png',
    'webp': 'main-img-v3.webp',
  },
  'main-img-pc': {
    'image': 'main-img-pc-v3.png',
  },
  'main-ring': {
    'image': 'main-ring.png',
    'webp': 'main-ring.webp'
  },
  'date-card-1': {
    'image': 'date-card-1.png',
    'webp': 'date-card-1.webp'
  },
  'date-card-2': {
    'image': 'date-card-2.png',
    'webp': 'date-card-2.webp'
  },
  'date-card-3': {
    'image': 'date-card-3.png',
    'webp': 'date-card-3.webp'
  },
  'date-pc-card-1': {
    'image': 'date-pc-card-1.png',
    'webp': 'date-pc-card-1.webp'
  },
  'date-pc-card-2': {
    'image': 'date-pc-card-2.png',
    'webp': 'date-pc-card-2.webp'
  },
  'date-pc-card-3': {
    'image': 'date-pc-card-3.png',
    'webp': 'date-pc-card-3.webp'
  },
  'icon-recycle': {
    'image': 'icon-recycle.png',
    'webp': 'icon-recycle.webp'
  },
  'method-step-1': {
    'image': 'method-step-1.png',
    'webp': 'method-step-1.webp'
  },
  'method-step-2': {
    'image': 'method-step-2.png',
    'webp': 'method-step-2.webp'
  },
  'method-step-3': {
    'image': 'method-step-3-img.png',
    'webp': 'method-step-3-img.webp'
  },
  'method-tab-1-img': {
    'image': 'method-tab-1-img.png',
    'webp': 'method-tab-1-img.webp'
  },
  'method-tab-2-img': {
    'image': 'method-tab-2-img-v2.png',
    'webp': 'method-tab-2-img-v2.webp'
  },
  'method-tab-2-img-pc': {
    'image': 'method-tab-2-img-pc-v2.png',
    'webp': 'method-tab-2-img-pc-v2.webp'
  },
  'method-tab-1-bg': {
    'image': 'method-tab-1-bg.png',
    'webp': 'method-tab-1-bg.webp'
  },
  'method-tab-2-bg': {
    'image': 'method-tab-2-bg.png',
    'webp': 'method-tab-2-bg.webp'
  },
  'btn-arrow-down': {
    'image': 'btn-arrow-down.png',
    'webp': 'btn-arrow-down.webp'
  },
  'btn-arrow-up': {
    'image': 'btn-arrow-up.png',
    'webp': 'btn-arrow-up.webp'
  },
  'btn-arrow-down-bg': {
    'image': 'btn-arrow-down-bg.png',
    'webp': 'btn-arrow-down-bg.webp'
  },
  'quiz-popup-img-1': {
    'image': 'quiz-popup-img-1.png',
    'webp': 'quiz-popup-img-1.webp'
  },
  'quiz-popup-img-2': {
    'image': 'quiz-popup-img-2.png',
    'webp': 'quiz-popup-img-2.webp'
  },
  'quiz-popup-img-3': {
    'image': 'quiz-popup-img-3.png',
    'webp': 'quiz-popup-img-3.webp'
  },
  'quiz-popup-img-4': {
    'image': 'quiz-popup-img-4-v2.png',
    'webp': 'quiz-popup-img-4-v2.webp'
  },
  'method-img-1': {
    'image': 'method-img-1.jpeg',
    'webp': 'method-img-1.webp'
  },
  'method-img-2': {
    'image': 'method-img-2.jpeg',
    'webp': 'method-img-2.webp'
  },
  'method-img-3': {
    'image': 'method-img-3.jpeg',
    'webp': 'method-img-3.webp'
  },
  'method-img-4': {
    'image': 'method-img-4.jpeg',
    'webp': 'method-img-4.webp'
  },
  'recycled-bottle-production-process-step-1': {
    'image': 'recycled-bottle-production-process-step-1.jpeg',
    'webp': 'recycled-bottle-production-process-step-1.webp'
  },
  'recycled-bottle-production-process-step-2': {
    'image': 'recycled-bottle-production-process-step-2.jpeg',
    'webp': 'recycled-bottle-production-process-step-2.webp'
  },
  'recycled-bottle-production-process-step-3': {
    'image': 'recycled-bottle-production-process-step-3.jpeg',
    'webp': 'recycled-bottle-production-process-step-3.webp'
  },
  'recycled-bottle-production-process-step-4': {
    'image': 'recycled-bottle-production-process-step-4.jpeg',
    'webp': 'recycled-bottle-production-process-step-4.webp'
  },
  'recycled-bottle-production-process-step-5': {
    'image': 'recycled-bottle-production-process-step-5-v3.png',
    'webp': 'recycled-bottle-production-process-step-5-v3.webp'
  },
  'page-main-logos-pc': {
    'image': 'page-main-logos-pc.png',
    'webp': 'page-main-logos-pc.webp',
  },
  'page-main-logos': {
    'image': 'page-main-logos.png',
    'webp': 'page-main-logos.webp',
  },
  'img-notice-pc': {
    'image': 'img-notice-pc.png',
    'webp': 'img-notice-pc.webp',
  },
  'img-notice': {
    'image': 'img-notice.png',
    'webp': 'img-notice.webp',
  },
  'image-notice-v2': {
    'image': 'image-notice-v2.png',
    'webp': 'image-notice-v2.webp',
  },
  'image-notice-v2-pc': {
    'image': 'image-notice-v2-pc.png',
    'webp': 'image-notice-v2-pc.webp',
  },
  'image-notice-v3': {
    'image': 'image-notice-v3.png',
    'webp': 'image-notice-v3.webp',
  },
  'image-notice-v3-pc': {
    'image': 'image-notice-v3-pc.png',
    'webp': 'image-notice-v3-pc.webp',
  },
};

export function getImages(supportWebp?: boolean) {
  return lodash.mapValues(imagesDef, val => {
    if (supportWebp && val.webp) {
      return `${IMAGE_BASE_URL}/${val.webp}`;
    }
    return `${IMAGE_BASE_URL}/${val.image}`;
  });
}
