import type { Component } from 'vue';

export interface IComponent {
  name: string;
  showController?: boolean;
  component?: Component;
  hasContainer: boolean;
  props: { [key: string]: any };
  hasController: boolean;
}

const components: {[key: string]: IComponent} = {
  Carousel: {
    name: 'Carousel',
    showController: false,
    component: require('./carousel.vue').default,
    hasContainer: false,
    props: {},
    hasController: true,
  },
  Program: {
    name: 'Program',
    showController: false,
    component: require('./program.vue').default,
    hasContainer: true,
    props: {},
    hasController: true,
  },
  // Product: {
  //   name: 'Product',
  //   showController: false,
  //   component: require('./product.vue').default,
  //   hasContainer: true,
  //   props: {},
  //   hasController: true,
  // },
  Story: {
    name: 'Story',
    showController: false,
    component: require('./story.vue').default,
    hasContainer: true,
    props: {},
    hasController: true,
  },
  Code: {
    name: 'Code',
    showController: false,
    component: require('./code.vue').default,
    hasContainer: false,
    props: {},
    hasController: true,
  },
  Collapse: {
    name: 'Collapse',
    showController: false,
    component: require('./collapse.vue').default,
    hasContainer: true,
    props: {},
    hasController: true,
  },
  Text: {
    name: 'Text',
    showController: false,
    component: require('./text.vue').default,
    hasContainer: true,
    props: {},
    hasController: false
  },
  Image: {
    name: 'Image',
    showController: false,
    component: require('./image.vue').default,
    hasContainer: true,
    props: {},
    hasController: true
  }
};

export default components;
