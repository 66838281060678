export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been submitted successfully!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will contact you soon , please stay tuned!"])},
      "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
    }
  })
}
