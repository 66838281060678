export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Verification code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code"])},
      "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "CAPTCHA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAPTCHA"])},
      "Captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captcha"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHONE"])},
      "Phone is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone is required"])},
      "Email address is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address is required"])},
      "Send verification code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send verification code"])},
      "Captcha is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captcha is required"])},
      "sended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sended"])}
    }
  })
}
