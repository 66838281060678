export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의 내용 찾아보기"])}
    }
  })
}
