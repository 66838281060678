export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "TerraCycle Recycling Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TerraCycle Recycling Rewards"])},
      "Your current have:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current have:"])},
      "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points"])},
      "Redeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem"])},
      "Redeem points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem points"])},
      "Add new charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new charity"])},
      "Please fill in the points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the points"])},
      "Not enough points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough points"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The operation is successful, please wait for the administrator to review"])},
      "Search for an existing charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for an existing charity"])},
      "Current Points Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Points Balance"])}
    }
  })
}
