export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 내용이 도움이 되었나요??"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아니오"])},
      "found_this_helpful": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("helpfulCount")), "/", _interpolate(_named("reviewsCount")), "이 도움이 되었습니다"])}
    }
  })
}
