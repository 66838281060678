<template>
  <svg
    t="1660880108979"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="4698"
    width="200"
    height="200"
  ><path d="M725.333333 128h149.184C886.421333 128 896 137.578667 896 149.482667V298.666667a42.666667 42.666667 0 1 0 85.333333 0V149.482667A106.752 106.752 0 0 0 874.517333 42.666667H725.333333a42.666667 42.666667 0 1 0 0 85.333333z m170.666667 597.333333v149.184c0 11.904-9.578667 21.482667-21.482667 21.482667H725.333333a42.666667 42.666667 0 1 0 0 85.333333h149.184A106.752 106.752 0 0 0 981.333333 874.517333V725.333333a42.666667 42.666667 0 1 0-85.333333 0z m-597.333333 170.666667H149.482667A21.418667 21.418667 0 0 1 128 874.517333V725.333333a42.666667 42.666667 0 1 0-85.333333 0v149.184A106.752 106.752 0 0 0 149.482667 981.333333H298.666667a42.666667 42.666667 0 1 0 0-85.333333zM128 298.666667a42.666667 42.666667 0 1 1-85.333333 0V149.482667A106.752 106.752 0 0 1 149.482667 42.666667H298.666667a42.666667 42.666667 0 1 1 0 85.333333H149.482667C137.578667 128 128 137.578667 128 149.482667V298.666667zM85.333333 554.666667a42.666667 42.666667 0 1 1 0-85.333334h853.333334a42.666667 42.666667 0 1 1 0 85.333334H85.333333z" fill="#ffffff" p-id="4699" />
  </svg>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
  .icon {
    width: 30px;
    height: auto;

    path {
      fill: $primary;
    }
  }
</style>
