export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Contact information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員情報"])},
      "Email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
      "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都道府県"])},
      "First name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
      "Zip code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
      "Last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
      "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市区町村"])},
      "Nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー名"])},
      "Phone number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
      "Age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年齢"])},
      "Organization name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["団体名"])},
      "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
      "Organization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["団体の種類"])},
      "People collecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収参加人数"])},
      "Edit Your Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員情報を編集する"])},
      "Connect your account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを連携する"])},
      "Connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連携済み"])},
      "Connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連携する"])},
      "Street Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市区町村以降"])},
      "Extended Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マンション・建物名"])}
    }
  })
}
