export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "remaining_number_tips": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can submit ", _interpolate(_named("num")), " more containers."])},
      "participate_join_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user has not participated in the recycling project, whether to help him participate?"])},
      "participate_join_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participate successfully."])}
    }
  })
}
