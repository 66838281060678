import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

interface IMessageEvent {
  id: string;
  type: string;
  event: string;
  data:  {[key: string]: any};
  isSucceed: boolean;
  isFailed: boolean;
  isCanceled: boolean;
  errorMessage?: string;
}

interface IEvent {
  success?: (data: {[key: string]: any}, errMsg: string) => void;
  fail?: (errMsg: string) => void;
  complete?: (errMsg: string) => void;
  cancel?: (errMsg: string) => void;
}

interface IInvokeData extends IEvent {
  data?: {[key: string]: any};
}

export interface IRequestParams extends IInvokeData {
  data: {
    url: string;
    params?: {[key: string]: any};
    data?: {[key: string]: any};
    config?: {[key: string]: any};
    method: 'get' | 'post' | 'put' | 'delete'
  }
}

export class AdminSdk {
  events: { [key: string]: IEvent } = {};

  messageHandler = (event: { origin: string, data: IMessageEvent }) => {
    if (origin !== window.origin) {
      return;
    }
    const { id, event: eventName, data, isSucceed, isFailed, isCanceled, errorMessage } = event.data;
    if (!id || !this.events[id]) {
      return;
    }
    const { success = _.noop, fail = _.noop, complete = _.noop, cancel = _.noop } = this.events[id];
    complete.call(this, {
      data,
      errMsg: `${eventName}:complete`
    });
    if (isSucceed) {
      success.call(this, {
        data,
        errMsg: `${eventName}:ok`
      });
    } else if (isFailed) {
      fail.call(this, {
        errMsg: `${eventName}:${errorMessage}`
      });
    } else if (isCanceled) {
      cancel.call(this, {
        errMsg: `${eventName}:cancel`
      });
    }
    delete this.events.id;
  }

  constructor() {
    window.addEventListener('message', this.messageHandler);
  }

  destroy() {
    this.events = {};
    window.removeEventListener('message', this.messageHandler);
  }

  invoke(event: string, data: IInvokeData = {}) {
    const id = uuidv4();
    const message = JSON.parse(JSON.stringify(
      {
        event,
        data: data.data,
        id
      }
    ));
    if (window.parent) {
      window.parent.postMessage(message, process.env.VUE_APP_ADMIN_SITE_ORIGIN);
      this.events[id] = _.omit(data, 'data');
    }
  }

  request(data: IRequestParams): Promise<{[key: string]: any}> {
    return new Promise((resolve, reject) => {
      this.invoke('request', {
        ...data,
        success: resolve,
        fail: reject
      });
    });
  }

  goBack() {
    this.invoke('goBack');
  }

  upload(data?: {[key: string]: any}): Promise<{[key: string]: any}> {
    return new Promise((resolve, reject) => {
      this.invoke('upload', {
        data,
        success: resolve,
        fail: reject
      });
    });
  }

}
