export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share this"])},
      "viewsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " views"])},
      "writing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Writing"])},
      "shareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The story of TerraCycle\nShare it"])},
      "copySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy success"])}
    }
  })
}
