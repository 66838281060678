export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Collection request has been completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 신청이 완료되었습니다."])},
      "Program Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캠페인 정보"])},
      "Program Submission day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캠페인 수거"])},
      "Type of Participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여 유형"])},
      "Status of application for activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청 현황"])},
      "My address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 주소"])},
      "Collection Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 정보"])},
      "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량"])},
      "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무게"])},
      "Tracking number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["송장번호"])},
      "Date created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성일"])},
      "Date received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입고일"])},
      "Points earned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적립된 포인트"])},
      "Status of colletion shippment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 현황"])},
      "Confirm cancellation?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소 하시겠습니까?"])},
      "Confirm request?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 신청을 진행 하시겠습니까?"])},
      "No collection application record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 신청 내역이 없습니다"])},
      "Rounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회차"])}
    }
  })
}
