export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Offline Collection Records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拠点回収履歴"])},
      "Collection Bin Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収ボックスの管理"])},
      "Creat New Submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい提出物を作成"])},
      "Collection method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収方法"])},
      "user_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー登録"])},
      "store_staff_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗スタッフ登録"])},
      "no_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録なし"])},
      "make_online_collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンラインコレクションを作る"])}
    }
  })
}
