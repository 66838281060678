import router from '@/router';
import urlJoin from 'url-join';
import { request } from './request';
import { RouteLocationNormalizedLoaded } from 'vue-router';

interface Params {
  route?: RouteLocationNormalizedLoaded;
  callback?: string;
  callbackOrigin?: string;
}

export async function loginGoBack(params: Params = {}) {
  const route = params.route || router.currentRoute.value;
  const callback = params.callback || decodeURIComponent((route.query.callback as string) || '');
  const callbackOrigin = params.callbackOrigin || decodeURIComponent(route.query.callback_origin as string || '');
  // 如果是 自定义域名跳转过来的，需要直接带上 sso_token 跳回去
  if (callbackOrigin) {
    const { data } = await request.get('auth/sso_token');
    window.location.href = urlJoin(callbackOrigin, callback, `?sso_token=${data.sso_token}`);
  } else {
    return router.replace(callback || { name: 'home' });
  }
}
