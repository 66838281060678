export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Redeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用する"])},
      "Redeem points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイントを使用する"])},
      "Not enough points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイントが不足しています"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作は成功しました。管理者が確認するまでお待ちください"])},
      "Current Points Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のポイント残高"])}
    }
  })
}
