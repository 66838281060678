export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    }
  })
}
