export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "individual_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인"])},
      "individual_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["혼자 참여하고 싶어요"])},
      "group_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단체명"])},
      "group_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가족 또는 친구와 참여하고 싶어요"])}
    }
  })
}
