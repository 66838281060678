export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "offline_collection_point_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오프라인 수거 장소"])},
      "tab_online_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["온라인 수거"])},
      "tab_offline_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오프라인 수거"])}
    }
  })
}
