import {
  createApp,
  h,
  reactive,
} from 'vue';
import LoadingComponent from '@/components/loading.vue';
// eslint-disable-next-line import/no-named-as-default
import BootstrapVue3 from 'bootstrap-vue-3';

let instance: any;

interface Options {
  value?: number,
  max?: number,
  visible?: boolean,
  showMask?: boolean,
}

export default function createLoadingComponent(options: Options = {}) {
  if (instance) {
    Object.assign(instance.data, { visible: true }, options);
    return instance;
  }
  const data = reactive({
    value: 1,
    max: 2,
    visible: true,
    delayed: 600,
    ...options,
  });

  const ElLoadingComponent = {
    name: 'LoadingComponent',
    setup() {
      return () => {
        return h(LoadingComponent, data);
      };
    }
  };
  const contentDOM = document.createElement('div');
  document.body.appendChild(contentDOM);
  const vm = createApp(ElLoadingComponent).use(BootstrapVue3).mount(contentDOM);
  return instance = {
    vm,
    data
  };
}
