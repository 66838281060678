import { BaseType, getBaseData } from './base';
import { IImage } from './image';

interface ICouponTemplate extends BaseType {
  name: string;
  description: string;
  coupon_display_type: 'qrcode' | 'barcode';
  coupon_type: 'general' | 'private_code' | 'image_coupon';
  started_at: string;
  ended_at: string;
  image?: IImage;
  coupon_image?:IImage;
  show_usage: boolean;
}

export interface ICoupon extends BaseType {
  coupon_code: string;
  collected_item_id: number;
  program_id: number;
  user_id: number;
  is_read: boolean;
  started_at: string;
  ended_at: string;
  coupon_display_type: 'qrcode' | 'barcode';
  qrcode?: IImage;
  barcode?: IImage;
  expired: boolean;
  used: boolean;
  oc_coupon_template: ICouponTemplate
}

export function getCouponDefaultData(): ICoupon {
  return {
    ...getBaseData(),
    coupon_code: '',
    collected_item_id: 0,
    program_id: 0,
    user_id: 0,
    is_read: false,
    started_at: '',
    ended_at: '',
    coupon_display_type: 'qrcode',
    expired: false,
    used: false,
    oc_coupon_template: {
      ...getBaseData(),
      name: '',
      description: '',
      coupon_display_type: 'qrcode',
      coupon_type: 'general',
      started_at: '',
      ended_at: '',
      show_usage: false
    }
  };
}
