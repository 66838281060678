import { IImage } from './image';

export interface IArticle {
  id: number;
  title: string;
  slug: string;
  type: string;
  faq_section_id?: number;
  article_category_id?: number;
  cover?: IImage;
  summary: string;
  content?: string; // 详情api有
  updated_at: string;
  author: string;
  helpful_count: number;
  not_helpful_count: number;
  likes_count: number;
  think_helpful: boolean;
  think_not_helpful: boolean;
  reviews_count: number;
  liked: boolean;
}

export function getArticleDefaultData(): IArticle {
  return {
    id: 0,
    title: '',
    slug: '',
    type: '',
    summary: '',
    updated_at: '',
    author: '',
    helpful_count: 0,
    not_helpful_count: 0,
    likes_count: 0,
    think_helpful: false,
    think_not_helpful: false,
    reviews_count: 0,
    liked: false,
  };
}
