export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "available_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用可能な時間"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEW"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])}
    }
  })
}
