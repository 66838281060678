export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submission"])},
      "CAPTCHA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAPTCHA"])},
      "Captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captcha"])}
    }
  })
}
