export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
      "Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
      "Actual payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual payment"])},
      "Order Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Information"])},
      "Order number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
      "Delivery Frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Frequency"])}
    }
  })
}
