export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Search programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search programs"])},
      "Redeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem"])}
    }
  })
}
