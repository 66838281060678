import { request } from '@/utils';
import { SimpleStore } from './helper/simple-store';
import getInitOrder, { IOrder } from '@/types/order';
import { TPurchaseKind } from '@/types/product';
import { IAddress } from '@/types/address';
import _ from 'lodash';

interface IPayment {
  oid: string;
  price: string;
  timestamp: string;
  version: string;
  goodName: string;
  buyerName: string;
  buyerEmail: string;
  buyerTel: string;
  currency: string;
  mid: string;
  acceptmethod: string;
  offerPeriod: string;
  mKey: string;
  signature: string;
  returnUrl: string;
  closeUrl: string;
}

export class CheckoutStore extends SimpleStore {
  order: IOrder = getInitOrder();
  lineItemsAttributes: Array<{ quantity: number; variant_id: number; purchase_kind: TPurchaseKind }> = [];
  offerStartedDate: string | null = null; // 定期自动扣费开始时间，eg：'2022-02-02'
  offerEndedDate: string | null = null; // 定期自动扣费结束时间，默认一年内有效
  payment: IPayment | null = null;
  addresses: IAddress[] = [];
  address_id: number | null = null;
  saveAddress: boolean = false;

  get selectedAddress() {
    if (this.address_id !== null) {
      return this.getAddressById(this.address_id);
    }
    return null;
  }

  async fetch() {
    if (!this.lineItemsAttributes.length) {
      // eslint-disable-next-line no-console
      console.error('lineItemsAttributes is empty');
      return;
    }
    const { data } = await request.post<IOrder>('/bean/orders/preview', {
      line_items_attributes: this.lineItemsAttributes,
      address: this.selectedAddress
    }, { loading: { silent: true } });
    this.order = data;
  }

  async createOrder() {
    const { data } = await request.post<IOrder>('/bean/orders', {
      line_items_attributes: this.lineItemsAttributes,
      save_address: this.saveAddress,
      address: this.selectedAddress
    });
    this.order = data;
    return data;
  }

  async pay() {
    const { data } = await request.post<IPayment>(`/bean/orders/${this.order.id}/request_payment`);
    this.payment = data;
  }

  async fetchAddresses() {
    const { data } = await request.get<IAddress[]>('/bean/addresses');
    this.addresses = data;
    this.initAddressId();
  }

  initAddressId() {
    if (!this.address_id) {
      this.address_id = _.get(this.addresses.find(item => item.is_default), 'id') || _.get(this.addresses[0], 'id');
    }
  }

  selectAddress(addressId: number) {
    this.address_id = addressId;
  }

  async updateAddress(address: IAddress) {
    let data = address;
    if (address.id) {
      const result = await request.put<IAddress>(`/bean/addresses/${address.id}`, address);
      data === result.data;
    }
    const updateItem = this.addresses.find(item => item.id === address.id);
    updateItem && Object.assign(updateItem, data);
  }

  async deleteAddress(addressId: number) {
    if (addressId) {
      await request.delete(`bean/addresses/${addressId}`);
    }
    if (this.address_id === addressId) {
      this.address_id = null;
      this.initAddressId();
    }
    const index = this.addresses.findIndex(item => item.id === addressId);
    if (index !== -1) {
      this.addresses.splice(index, 1);
    }
  }

  saveNewAddress(address: IAddress) {
    this.address_id = address.id;
    const currentItem =  this.getAddressById(address.id);
    if (currentItem) {
      Object.assign(currentItem, address);
    } else {
      this.addresses.unshift(address);
    }
  }

  async saveNewAddressToServer() {
    const newAddress = this.getAddressById(0);
    if (newAddress) {
      const { data } = await request.post<IAddress>('/bean/addresses', _.omit(newAddress, 'id'));
      if (this.address_id === newAddress.id) {
        this.address_id = data.id;
      }
      Object.assign(newAddress, data);
    }
  }

  getAddressById(addressId: number) {
    return this.addresses.find(item => item.id === addressId);
  }

  setSaveAddrss(saveAddress: boolean) {
    this.saveAddress = saveAddress;
  }

};
