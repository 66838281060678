export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Basic information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本情報"])},
      "Email information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメール情報"])},
      "Password information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード情報"])},
      "Delete Terracycle Account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テラサイクルアカウントを削除しますか?"])},
      "Successful authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証に成功しました"])},
      "Delete confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["環境のためにテラサイクルを選んでいただきありがとうございます。本当に退会されますか？"])}
    }
  })
}
