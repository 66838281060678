export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["년"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일"])},
      "button_age_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年龄认证"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
    }
  })
}
