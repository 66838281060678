export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Basic information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])},
      "Email information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email information"])},
      "Password information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password information"])},
      "Delete Terracycle Account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Delete Terracycle Account?"])},
      "Successful authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful authentication"])},
      "Delete confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for choosing TerraCycle to help the environment. Are you sure you want to unsubscribe?"])}
    }
  })
}
