export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "banner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
      "banner_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through our variety of recycling platforms, we’ll help you find a solution for almost all of your waste."])}
    }
  })
}
