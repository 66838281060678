import {
  createApp,
  h,
  reactive,
  ComponentPublicInstance,
  App,
  Slots,
  shallowRef
} from 'vue';
import DialogComponent from '@/components/dialog.vue';
// eslint-disable-next-line import/no-named-as-default
import BootstrapVue3 from 'bootstrap-vue-3';
import _ from 'lodash';
import { ColorVariants } from '@/constants';
import i18n from '@/i18n';

export type DialogBtn = {
  text: string,
  click?: Function,
  style?: ColorVariants,
  disabled?: boolean,
  loading?: boolean
}
export interface DataInterface {
  class?: string;
  isCenter?: boolean,           // 是否垂直居中
  visible?: boolean,            // v-modal:visible
  showWarnIcon?: boolean,       // 是否显示警告图标
  title?: string,               // 标题
  content?: string,            // 内容
  btns?: DialogBtn[],          // 多个按钮
  btnText?: string,            // 单个按钮文字
  btnStyle?: ColorVariants     // 单个按钮样式
}

export type ConfirmOptions = {
  content?: string;
  showCancel?: boolean
  confirmText?: string;
  cancelText?: string;
  class?: string;
}

export class Modal {
  vm: ComponentPublicInstance | null
  app: App | null
  closeCallback: Function | null = null
  contentDOM: HTMLDivElement | null = document.createElement('div');

  defaultData = {
    title: '',
    content: '',
    visible: false,
    // 韩文环境alert弹窗关闭文案需要改为确定
    btnText: i18n.global.t(i18n.global.locale.value === 'ko' ? 'Confirm' : 'Close'),
  }

  data: DataInterface = reactive(_.cloneDeep(this.defaultData));
  slots = shallowRef<Slots>({});

  constructor(options: DataInterface = {}, slots?: Slots) {
    Object.assign(this.data, this.defaultData, options);
    if (slots) {
      this.slots.value = slots;
    }
    const _this = this;
    const ElModalComponent = {
      name: 'DialogComponent',
      setup() {
        return () => {
          return h(DialogComponent as any, {
            ..._this.data,
            visible: _this.data.visible,
            'onUpdate:visible': _this.onUpdateVisible,
            onConfirm: _this.hanedleConfirm
          }, _this.slots.value);
        };
      }
    };

    document.body.appendChild((this.contentDOM as HTMLDivElement));
    this.app = createApp(ElModalComponent);
    this.app.use(i18n);
    this.vm = this.app.use(BootstrapVue3).mount((this.contentDOM as HTMLDivElement));
  }

  onUpdateVisible = (val: boolean) => {
    if (val) {
      this.data.visible = val;
    } else {
      // 有需要调用 close callback
      this.close();
    }
  }

  hanedleConfirm = () => {
    this.close('confirm');
  }

  open = (options?: DataInterface | string, slots?: Slots) => {
    return new Promise(resolve => {
      if (typeof options === 'string') {
        options = { content: options };
      }
      Object.assign(this.data, this.defaultData, options);
      this.slots.value = slots || {};
      this.data.visible = true;
      this.closeCallback = resolve;
    });
  }

  close = (type?: string) => {
    this.data.visible = false;
    if (_.isFunction(this.closeCallback)) {
      this.closeCallback({ type: type || 'close' });
      // 调用单次后要删除
      this.closeCallback = null;
    }
  }

  destroy = () => {
    this.data.visible = false;
    this.vm = null;
    this.app = null;
    this.data = {};
    document.body.removeChild(this.contentDOM as HTMLDivElement);
    this.contentDOM = null;
  }
}

export const alert = (new Modal({ class: 'dialog-global-alert' })).open;

export const confirm = async (title: string, opts?: ConfirmOptions) => {
  const modal = new Modal();
  const options = opts || {};
  const showCancel = typeof options.showCancel === 'boolean' ? options.showCancel : true;
  const btns = [
    {
      text: options.confirmText || i18n.global.t('Confirm'),
      style: ColorVariants.primary,
      click() {
        modal.close('confirm');
      }
    }
  ];
  if (showCancel) {
    btns.unshift({
      text: options.cancelText || i18n.global.t('Cancel'),
      style: ColorVariants.outlinePrimary,
      click() {
        modal.close('cancel');
      }
    });
  }
  const data: DataInterface = {
    title: title,
    content: options.content,
    class: `dialog-global-alert ${options.class}`,
    btns: btns
  };
  return new Promise((resolve, reject) => {
    modal.open(data).then((res: any) => {
      if (res.type === 'confirm') {
        resolve(true);
      } else {
        reject(new Error('Cancel'));
      }
    }).finally(() => {
      setTimeout(() => {
        modal.destroy();
      }, 500);
    });
  });
};
