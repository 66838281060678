import _ from 'lodash';
import qs from 'qs';
import axios, { AxiosRequestConfig } from 'axios';
import decoder from './decoder';
import createLoadingComponent from './loading';
import { alert } from './modal';
import { authStore } from '@/stores';
import router from '@/router';
import i18n from '@/i18n';
import { currentI18nLocale, CAPTCHA_RCODE_SESSION_KEY } from '@/constants';
import { goToLogin } from './go-to-login';
import urlJoin from 'url-join';

const t = i18n.global.t;
interface LoadingData {
  maxCount: number,
  loadingInstance: any,
  loadingSet: Set<any>,
}

const loadingData: LoadingData = {
  maxCount: 0,
  loadingInstance: null,
  loadingSet: new Set(),
};

const { VUE_APP_SITE_ORIGIN, VUE_APP_WEB_API, VUE_APP_ENV } = process.env;

const request = axios.create({
  baseURL: VUE_APP_ENV === 'development' ? '/app_api/v1' : urlJoin(VUE_APP_WEB_API, '/app_api/v1'),
  timeout: 30000,
  withCredentials: true, // 验证码需要通过cookie
  headers: { 'Content-Type': 'application/json', 'locale': currentI18nLocale },
  paramsSerializer(params: object) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});

request.interceptors.request.use(config => {
  if (config.headers && !config.headers['Authorization']) {
    config.headers['Authorization'] = authStore.access_token;
  }
  if (config.headers && window[CAPTCHA_RCODE_SESSION_KEY]) {
    config.headers['Captcha-Rcode'] = window[CAPTCHA_RCODE_SESSION_KEY];
  }
  handleLoadingStart(config);
  return config;
});

request.interceptors.response.use(
  (res: any) => {
    res.isResponse = true;
    res.meta = {};
    handleLoadingStop(res.config);
    _.forEach(res.headers, (v, k) => {
      if (/^x-/i.test(k)) {
        const key = _.snakeCase(k.replace(/^x-/i, ''));
        res.meta[key] = decoder(v);
      }
    });
    return res;
  },
  async (err: { message: any; code: number; status: any; [key: string]: any }) => {
    const response = _.get(err, 'response', {});
    handleLoadingStop(err.config);
    if (response.data) {
      const { error_message, messages, error, code } = response.data;
      err.message = error_message || messages || error || err.message;
      err.code = code;
    }
    err.status = response.status;
    if (response.status === 401) {
      _.set(err.config, 'headers[Authorization]', '');
      await authStore.signOut();
      const go = goToLogin(false);
      // 如果是自定义域名，需要先跳到主域名下，登录完成后再跳回来
      if (VUE_APP_SITE_ORIGIN !== window.location.origin) {
        await alert({ title: t('The session has expired. Please log in again') });
        go();
      } else {
        go();
        alert({ title: t('The session has expired. Please log in again') });
      }
      return Promise.reject(err);
    }
    if (err.code === 40304) {
      // 40304就弹窗接口返回提示并退出登录
      _.set(err.config, 'headers[Authorization]', '');
      await authStore.signOut();
      goToLogin();
      alert({ title: err.message });
      return Promise.reject(err);
    }
    if (err.code === 40305) {
      router.push({ name: 'mine.memberProfile.edit', query: { type: 'email' } });
      alert({ title: err.message });
      return Promise.reject(err);
    }
    if (_.get(err.config.loading, 'silent') !== true) {
      alert({ title: err.message });
    }
    return Promise.reject(err);
  }
);

function handleLoadingStart(config: AxiosRequestConfig = {}) {
  if (config.loading === false) {
    return;
  }
  const showMask = config.loading && config.loading instanceof Object
    ? config.loading.showMask || true
    : true;
  const { loadingSet } = loadingData;
  loadingSet.add(config);
  loadingData.maxCount++;
  loadingData.loadingInstance = createLoadingComponent({
    value: loadingSet.size,
    max: loadingData.maxCount + 1,
    showMask,
  });
}

function handleLoadingStop(config: AxiosRequestConfig) {
  if (config.loading === false) {
    return;
  }
  const { loadingSet } = loadingData;
  if (loadingSet.has(config)) {
    loadingSet.delete(config);
    loadingData.loadingInstance.data.value = loadingData.maxCount - loadingSet.size + 1;
  }
  if (loadingSet.size === 0) {
    loadingData.loadingInstance.data.visible = false;
    loadingData.maxCount = 0;
  }
}

export { request };
