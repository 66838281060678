import { request } from './request';
import { IProgram } from '@/types/program';
import _ from 'lodash';

export async function requestCollection(program: IProgram): Promise<{ status: 'success' | 'wait_list' } | undefined> {
  try {
    await request.post('user_requests', {
      target_id: program.id,
      target_type: 'Program',
      type: 'CollectionRequest',
      people_collection: program.people_collection,
      group_name: program.group_name
    }, {
      loading: { silent: true }
    });
    return {
      status: 'success'
    };
  } catch (e: any) {
    const code = _.get(e, 'code');
    // 项目人次达到上限，需要显示错误信息并提示是否订阅 waiting list
    if (String(code) === '40008') {
      await request.post('request_waiting_lists', {
        kind: 'collection',
        program_id: program.id
      });
      return {
        status: 'wait_list'
      };
    }
    // request.ts已经处理过这个code了
    if (String(code) !== '40305') {
      throw e;
    }
  }
}
