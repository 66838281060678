export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "Tracking number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking number"])},
      "Date created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date created"])},
      "Date received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date received"])},
      "Recover amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover amount"])},
      "Points earned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points earned"])},
      "Shippment history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shippment history"])},
      "Confirm cancellation?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm cancellation?"])},
      "Confirm request?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm request?"])},
      "No collection history record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No collection history record"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
    }
  })
}
