export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "request_collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Collection"])},
      "program_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program detail"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submission day"])},
      "close_confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave this program?"])},
      "request_offline_collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Offline Collection"])},
      "collection_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection challenge"])}
    }
  })
}
