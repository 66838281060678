export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Searh campaign participation record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캠페인 참여 기록 검색"])},
      "No Program participation record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캠페인 참여 기록이 없습니다"])}
    }
  })
}
