import { request } from '@/utils';
import { SimpleStore } from './helper/simple-store';
import { ISeoMeta } from '@/types/seo-meta';
import { IImage } from '@/types/image';

type GoogleDialogConfig = {
  intent: string;
  client_id: string;
  chat_title: string;
}

interface Settings {
  customer_service_email?: string,
  google_dialog_config_vars?: GoogleDialogConfig;
  official_facebook_link: string;
  official_twitter_link: string;
  official_instagram_link: string;
  official_youtube_link: string;
  official_linkedin_link: string;
  org_type_list?: string[],
  charity_org_type_list?: string[],
  user_destroy_account_reasons?: string[],
  seo_meta?: ISeoMeta;
  sign_up_form_additional_checkbox1?: {
    content: string;
    is_show: boolean;
  };
  sign_up_form_additional_checkbox2?: {
    content: string;
    is_show: boolean;
  };
  line_qrcode?: IImage;
  min_donate_points: number;
  story_category_background_color: string;
  story_category_font_color: string;
  story_tag_background_color: string;
  story_tag_font_color: string;
}

class SettingStore extends SimpleStore {
  data: Settings = {
    official_facebook_link: '',
    official_twitter_link: '',
    official_instagram_link: '',
    official_youtube_link: '',
    official_linkedin_link: '',
    seo_meta: {},
    min_donate_points: 0,
    story_category_background_color: '#EECC3F',
    story_category_font_color: '#000000',
    story_tag_background_color: '#A8DD97',
    story_tag_font_color: '#000000',
  }
  async fetch() {
    const res = await request.get<Settings>('/settings');
    this.data = res.data;
  }
}

export const settingStore = SettingStore.create<SettingStore>();
