export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are applying for the PDO of ", _interpolate(_named("program"))])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once registered as a collection point, it will be posted on the map as one collection point for this program."])},
      "facility_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Name"])},
      "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
      "address_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 1"])},
      "address_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 2"])},
      "opening_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Time"])},
      "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure your input the correct information, when your request has approval, the PDO information will list on the map of our collection location."])},
      "show_phone_on_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether to display the mobile phone number on the map"])},
      "phone_number_tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter only if you wish to post your phone number."])}
    }
  })
}
