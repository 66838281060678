export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["従来リサイクルされてこなかったものをリサイクルする。"])},
      "Search story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索する"])}
    }
  })
}
