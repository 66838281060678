export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "My page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My page"])}
    }
  })
}
