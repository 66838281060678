export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再読み込み"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
      "toogleHasContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幅を広げる"])},
      "moveUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上へ"])},
      "moveDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下へ"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集する"])},
      "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開する"])}
    }
  })
}
