export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Redeem my points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem my points"])},
      "Redeem history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem history"])},
      "Your current have:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current have:"])},
      "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points"])},
      "Favorite charities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite charities"])},
      "texts": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have a favorite charity item. "])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all charities"])},
        
      ]
    }
  })
}
