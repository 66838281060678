import { BaseType, getBaseData } from './base';
import { IImage } from './image';

interface ProgramType extends BaseType {
  cn: string;
  tn: string;
  type: string;
  campaign_quiz_id: number;
  name: string;
  joined_members_count: number;
  open_slots: number;
  started_at: string;
  ended_at: string;
  image: IImage;
  published_at: string;
  max_number_of_active_collections: number;
  max_number_of_user_active_collections: number;
  max_number_of_user_individual_type_collections: number;
  max_number_of_user_group_type_collections: number;
  slug: string;
  custom_campaign_domain: string;
  brand: string
}

export interface PointsType extends BaseType {
  cn: string,
  tn: string,
  points: number,
  program: ProgramType,
}

export function getPointDefaultData(): PointsType {
  return {
    ...getBaseData(),
    cn: '',
    tn: '',
    points: 0,
    program: {
      ...getBaseData(),
      cn: '',
      tn: '',
      type: '',
      campaign_quiz_id: 0,
      name: '',
      joined_members_count: 0,
      open_slots: 0,
      started_at: new Date().toJSON(),
      ended_at: new Date().toJSON(),
      image: {
        id: '',
        signed_id: '',
        content_type: '',
        filename: '',
        byte_size: '',
        url: ''
      },
      published_at: new Date().toJSON(),
      max_number_of_active_collections: 0,
      max_number_of_user_active_collections: 0,
      max_number_of_user_individual_type_collections: 0,
      max_number_of_user_group_type_collections: 0,
      slug: '',
      custom_campaign_domain: '',
      brand: ''
    }
  };
}

export default getPointDefaultData;
