export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "request_collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거신청"])},
      "program_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캠페인 참여 내역으로 돌아가기"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거품목 접수일"])},
      "close_confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여하고 계신\n재활용 캠페인을 취소하시겠습니까?\n\n취소할 경우,\n다시 참여 신청이 어려울 수 있습니다"])},
      "request_offline_collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오프라인 수거 요청"])},
      "collection_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재활용 챌린지"])}
    }
  })
}
