export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Media Room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["뉴스룸"])},
      "desc": [
        (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["테라사이클코리아 뉴스룸에 오신 것을 환영합니다. 테라사이클코리아와 협력사들이 함께 손잡고 환경을 지켜나가는 여정에 대한 소식을 담은 최신 보도자료들을 확인 하세요. 관련 문의는 <a href='mailto:", _interpolate(_named("email")), "'>", _interpolate(_named("email")), "</a> 으로 문의 바랍니다."])},
        
      ]
    }
  })
}
