export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제출하기"])},
      "CAPTCHA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보안인증"])},
      "Captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보안인증"])}
    }
  })
}
