export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request to become a PDO"])},
      "btn_tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once registered as a collection point, it will be posted on the map as one collection point for this program."])}
    }
  })
}
