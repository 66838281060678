export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the TerraCycle® US Help Center!"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask us a question!"])}
    }
  })
}
