export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈페이지"])},
      "My page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마이 페이지"])}
    }
  })
}
