export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "New Charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Charity"])},
      "TerraCycle Recycling Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TerraCycle Recycling Rewards"])},
      "Please fill in all required fields below.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in all required fields below."])},
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "Organization Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Type"])},
      "Address 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 1"])},
      "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "Zip code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
      "Contact Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person"])},
      "Contact Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Phone"])},
      "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "Federal Tax ID Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Federal Tax ID Number"])}
    }
  })
}
