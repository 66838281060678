export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Delete Terracycle Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テラサイクルアカウントを削除する"])},
      "REASON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由"])},
      "Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
      "If you click the confirm button, your terracycle account will be deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認ボタンをクリックすると、アカウント削除が完了します"])},
      "Are you sure to delete your account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本当にアカウントを削除しますか?"])},
      "Please add your phone number first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号を入力してください"])}
    }
  })
}
