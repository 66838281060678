export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "AGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年齢"])},
      "GENDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
      "HOW DID YOU HEAR ABOUT US?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["どこでテラサイクルを知りましたか?"])},
      "ORGANIZATION NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["団体名"])},
      "ORGANIZATION TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["団体の種類"])},
      "PEOPLE COLLECTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回収参加人数"])},
      "organization_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["団体名"])},
      "people_collection_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0より大きい整数を入力してください"])}
    }
  })
}
