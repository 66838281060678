export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Sign in via Terracycle website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테라사이클 회원가입"])},
      "or Sign in via SNS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["또는 SNS로 회원가입"])},
      "Sign in via SNS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS 로그인"])},
      "Don't have an account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정이 없으신가요?"])}
    }
  })
}
