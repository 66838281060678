import router from '@/router';
import urlJoin from 'url-join';
import { NavigationFailure } from 'vue-router';

type ReturnType = Promise<void | NavigationFailure | undefined> | string

interface Fn {
  (): ReturnType
}

type QueryType = {
  callback?: string,
  callback_origin?: string,
}

export function goToLogin(immediate: boolean = true): Fn {
  const route = router.currentRoute.value;
  let go: Fn;
  const query: QueryType = {};
  const ignorecCallback = /forgetPassword|^signup|^login/;
  if (!ignorecCallback.test(route.name as string)) {
    query.callback = encodeURIComponent(route.fullPath);
  }
  // 如果是自定义域名，需要先跳到主域名下，登录完成后再跳回来
  if (process.env.VUE_APP_SITE_ORIGIN !== window.location.origin && process.env.VUE_APP_ENV !== 'development') {
    const callbackOrigin = encodeURIComponent(window.location.origin);
    const fullPath = router.resolve({ name: 'login', query: { ...query, callback_origin: callbackOrigin } }).fullPath;
    go = () => window.location.href = urlJoin(process.env.VUE_APP_SITE_ORIGIN, fullPath);
  } else {
    go = () => router.push({ name: 'login', query });
  }
  if (immediate) {
    const result = go();
    // return 相同类型出去，外部使用好处理一点
    return () => result;
  } else {
    return go;
  }
}
