export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リクエストは正常に送信されました！"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請を受け付けました。\n\n申請が承認されましたら回収拠点として地図に掲載がされます。\n申請結果はメールでお知らせします。 "])},
      "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認しました"])}
    }
  })
}
