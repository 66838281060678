export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Searh campaign participation record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プログラムを検索する"])},
      "No Program participation record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リサイクルプログラムへの参加記録がありません"])}
    }
  })
}
