export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Collection request has been completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集荷依頼を受付けました"])},
      "Program Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リサイクルプログラム情報"])},
      "Program Submission day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Submission day"])},
      "Type of Participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Participation"])},
      "Status of application for activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status of application for activities"])},
      "My address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所情報"])},
      "Collection Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集荷情報"])},
      "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
      "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重量"])},
      "Tracking number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トラッキング番号"])},
      "Date created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成日"])},
      "Date received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受領日"])},
      "Points earned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得ポイント"])},
      "Status of colletion shippment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集荷状況"])},
      "Confirm cancellation?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルしますか？"])},
      "Confirm request?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集荷を依頼しますか？"])},
      "No collection application record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集荷依頼の記録がありません"])},
      "Rounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rounds"])}
    }
  })
}
