export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "step": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Step ", _interpolate(_named("index"))])},
      "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan QR barcode on the collected item"])},
      "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you uploaded the information, you can drop the container into the bin"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I’m Done"])},
      "add_more_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more item"])},
      "collection_bin_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You're collecting for <span class=\"strong\">", _interpolate(_named("name")), "</span>"])},
      "remaining_number_tips": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can submit ", _interpolate(_named("num")), " more containers."])},
      "scan_tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to scan the barcode on the collection item. If the camera doesn't work, please enter the numbers below the barcode."])}
    }
  })
}
