export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "available_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンの有効期限"])},
      "go_shopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い物に行く"])},
      "coupon_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン詳細"])},
      "make_this_coupon_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用済み"])},
      "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品購入前に「利用済みにする」を押してしまうと無効となります。必ずお会計時にクーポンをご提示ください。"])},
      "make_this_coupon_used_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用済みにする\n＊一度利用済みにしてしまうと戻すことができません。"])}
    }
  })
}
