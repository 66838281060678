export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테라사이클의 다양한 이야기들을 실어 소개합니다"])},
      "Search story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스토리 검색 하기"])}
    }
  })
}
